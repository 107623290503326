import { useState,useContext} from "react";
import {Tabs} from "@shopify/polaris";
import { DataContext } from "../DataContext";

function FilesTab() {
  
  const { codeData,setCurrentCode,currentFileName,setCurrentFileName,fileNames} = useContext(DataContext);
  const [selected, setSelected] = useState(fileNames.indexOf(currentFileName));
  const [itemStrings, setItemStrings] = useState(fileNames);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const updateCurrentCode = (index)=>{
    setSelected(index);
    const selectedFileName = itemStrings[index];
    setCurrentFileName(selectedFileName);
    setCurrentCode(codeData[selectedFileName]);
  }

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs= itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: ()=>{},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: 'rename',
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: 'duplicate',
              onPrimaryAction: async (name) => {
                await sleep(1);
                duplicateView(name);
                return true;
              },
            },
            {
              type: 'edit',
            },
            {
              type: 'delete',
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));


  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  return (
    <div style={{borderBottom:"var(--p-border-width-025) solid var(--p-color-border-secondary)",marginTop:"calc(-4 * var(--p-border-width-025))"}}>
      <Tabs
        tabs={tabs}
        selected={selected}
        onSelect={updateCurrentCode}
        canCreateNewView
        onCreateNewView={onCreateNewView}
        disclosureText="file"
      >
      </Tabs>
    </div>
    
  );
}

export default FilesTab;
