import {useRef,useEffect, useContext,useCallback} from "react";
import AceEditor from 'react-ace';
import { DataContext } from "../DataContext";
import 'ace-builds/src-noconflict/ext-language_tools';
import ace from 'ace-builds/src-noconflict/ace';
ace.config.set('basePath', process.env.PUBLIC_URL + '/ace-builds');
ace.config.set("modePath", process.env.PUBLIC_URL + '/ace-builds');
ace.config.set("workerPath", process.env.PUBLIC_URL + '/ace-builds'); 
ace.config.set("themePath", process.env.PUBLIC_URL + '/ace-builds'); 

ace.EditSession.prototype.toJSON = function() {
    return {
        annotations: this.getAnnotations(),
        breakpoints: this.getBreakpoints(),
        folds: this.getAllFolds().map(function(fold) {
            return fold.range;
        }),
        history: {
            undo: this.getUndoManager().$undoStack,
            redo: this.getUndoManager().$redoStack,
            rev: this.getUndoManager().$rev,
            mark: this.getUndoManager().mark
        },
        mode: this.getMode().$id,
        scrollLeft: this.getScrollLeft(),
        scrollTop: this.getScrollTop(),
        selection: this.getSelection().toJSON()
        //value: this.getValue()
    };
};

const CodeEditor = ()=>{
    const { codeEditorHeight, currentCode,codeData, setCodeData,currentFileName,fetchCodeData,editorState, setEditorState,setCurrentCodeEditor} = useContext(DataContext);
    const editorRef = useRef(null);
    const presetationRef = useRef(null);

    const restoreEditorState = useCallback(() => {
        const editor = editorRef.current.editor;
        let session = JSON.parse(editorState[currentFileName]);
    
        editor.session.getUndoManager().markClean();
        editor.session.getUndoManager().reset();//to prevent undoing the whole code
        //editor.session.setUndoManager(new ace.UndoManager());

        const UndoManagerObj = ace.require("ace/undomanager").UndoManager;
        editor.session.setUndoManager(new UndoManagerObj());
    
        session.folds.forEach(function(fold) {
            editor.session.addFold("...", ace.Range.fromPoints(fold.start, fold.end));
        });
    
        editor.session.setAnnotations(session.annotations);
        editor.session.setBreakpoints(session.breakpoints);    
        editor.session.$undoManager.$undoStack = session.history.undo;
        editor.session.$undoManager.$redoStack = session.history.redo;
        editor.session.$undoManager.$rev = session.history.rev;
        editor.session.$undoManager.mark = session.history.mark;
        editor.session.setMode(session.mode);
        editor.session.setScrollLeft(session.scrollLeft);
        editor.session.setScrollTop(session.scrollTop);
        editor.session.selection.fromJSON(session.selection);

    },[currentFileName,editorState]);

    useEffect(() => {
        //call this to fetch code data when the editor page is loaded initially
        // if (!currentCode) {
        //     fetchCodeData();
        // }

        if (editorRef.current) {

            setCurrentCodeEditor(editorRef.current.editor); //set the current ace editor

            if(currentFileName in editorState){
                restoreEditorState(currentFileName);
            }else{
                const editor = editorRef.current.editor;
                const session = editor.getSession();

                // Resetting the UndoManager
                const undoManager = session.getUndoManager();            
                undoManager.reset();
                undoManager.markClean();

                const UndoManagerObj = ace.require("ace/undomanager").UndoManager;
                session.setUndoManager(new UndoManagerObj());

                session.getSelection().clearSelection();
                session.getSelection().moveCursorTo(0, 0); 
                session.setScrollTop(0);
            }            

        }else{
            setCurrentCodeEditor(null);
        }
    }, [currentCode,fetchCodeData,currentFileName,editorState,restoreEditorState,setCurrentCodeEditor]);

    useEffect(() => {
        const resizeEditor = () => {
            if (editorRef.current) {
                editorRef.current.editor.resize();
            }
        };

        window.addEventListener('resize', resizeEditor);

        resizeEditor();
  
        return () => {
            window.removeEventListener('resize', resizeEditor);
        };
    }, []);
  
    const handleChange = useCallback(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            const session = editor.getSession();
            const newCode = session.getValue();    
            codeData[currentFileName] = newCode;
            setCodeData(codeData);
        }
    }, [currentFileName,codeData,setCodeData]);

    const storeState = useCallback(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            editorState[currentFileName] = JSON.stringify(editor.getSession());
            setEditorState(editorState);
            
        }
    }, [currentFileName,editorState,setEditorState]);

    const displayPresentation = ()=>{
        const editor = editorRef.current.editor;
        const scrollTop = editor.session.getScrollTop();
        if (scrollTop > 0) {
            presetationRef.current.style.visibility = "visible";
        }else{
            presetationRef.current.style.visibility = "hidden";
        }
    }

    return (
        <>
        <div ref={presetationRef} role="presentation" ariahidden="true" className="scroll-decoration" style={{visibility:"hidden",width: "100%",position: "relative",height: "3px",boxShadow:"#000 0 3px 2px -3px inset"}}></div>
        <AceEditor
            ref={editorRef}
            mode="javascript"
            theme="chrome"
            onChange={handleChange}
            name="editor"
            onScroll={displayPresentation}
            onBlur={storeState}
            value={currentCode}
            editorProps={{ $blockScrolling: true }}
            style={{ width: '100%', height: codeEditorHeight, font:"0.8125rem / normal 'monolisa','Space Mono','monospace'" }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine:true,
                showPrintMargin:false,
                fixedWidthGutter:true,
                enableLiveAutocompletion: true,
                useWorker: true,                    
            }}
        />
        </>
    )
}

export default CodeEditor;