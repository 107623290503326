import { Modal} from '@shopify/polaris';
import { ExternalIcon } from '@shopify/polaris-icons';
import parse from 'html-react-parser';
import { useEffect, useState} from 'react';
import "../assets/styles/github-themes.css";

// Async function to get data
const getData = async (handle) => {
    const request_data = { handle };

    const fetch_url = "/api/get-help-topic";
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(request_data),
        redirect: 'follow'
    };

    try {
        const response = await fetch(fetch_url, requestOptions);
        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return null;
    }
}

// DocsModal component using hooks for async data
const DocsModal = ({ active, onClose, topics }) => {
    const [docsContent, setDocsContent] = useState('');
    
    useEffect(() => {
        const fetchData = async () => {
            const payload = await getData(topics.handle);
            setDocsContent(payload);
        };

        if (active) {
            fetchData();
        }
    }, [topics.handle, active,setDocsContent]);


    return (
        <Modal
            size='large'
            open={active}
            onClose={onClose}
            title={topics.title}
            primaryAction={{
                content: 'Close',
                onAction: onClose
            }}
            secondaryActions={[{
                content: "View content",
                icon: ExternalIcon,
                onAction: () => { window.open(`https://help.jsrates.com/article/${topics.handle}`, "_blank") }
            }]}
        >
            <Modal.Section flush>
                <div className="help-section" htmlFor="html-export">{parse(docsContent || '')}</div>
            </Modal.Section>
        </Modal>
    );
};

export default DocsModal;
