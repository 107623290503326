import {useState, useCallback} from 'react';
import {Modal,Box, TextField, DropZone, Thumbnail, Text, BlockStack,InlineStack,Button,Banner} from '@shopify/polaris';
import {NoteIcon, XIcon} from '@shopify/polaris-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../assets/styles/contact-modal.css';

const ContactModal = ({ active, onClose}) => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [files, setFiles] = useState([]);
    const [quillValue, setQuillValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = useCallback((value) => {setEmail(value);validateEmail(value)?setEmailError(''):setEmailError('Please enter a valid email address.')}, []);
    const handleSubjectChange = useCallback((value) => {setSubject(value);value?setSubjectError(""):setSubjectError("Please enter subject")}, []);
    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFiles((files) => [...files, ...acceptedFiles]),
        [],
    );

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const fileUpload = (
        <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
    );

    const removeFile = (index) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const uploadedFiles = files.length > 0 && (
        
        <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
                Attachments
            </Text>
            {files.map((file, index) => (
                <InlineStack gap="200" align="start" key={index}>
                    <Thumbnail
                        size="small"
                        alt={file.name}
                        source={
                            validImageTypes.includes(file.type)
                            ? window.URL.createObjectURL(file)
                            : NoteIcon
                        }
                    />
                    <div>
                        {file.name}{' '}
                        <Text variant="bodySm" as="p">
                            {file.size} bytes
                        </Text>
                    </div>
                    <Button onClick={() => removeFile(index)} icon={XIcon} variant="tertiary" accessibilityLabel="Remove file" />
                </InlineStack>
            ))}
        </BlockStack>
    );

    
    const handleSubmit = ()=>{
        setLoading(true);
        setStatusMessage('');

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false);
            return;
        } else {
            setEmailError('');
        }

        if(subject === ""){
            setSubjectError("Please enter subject");
            setLoading(false);
            return;
        }else{
            setSubjectError("");
        }

		const description = `Reply email: ${email}\n\n ${quillValue}`;

		const formdata = new FormData();
		formdata.append("subject", subject);
		formdata.append("body", description);
		
        const attached_size = files.length;

		if(attached_size > 0){		
			formdata.append("attachment_size",attached_size);	
			for(let i=0;i<attached_size;i++){
				formdata.append("attachment"+i,files[i]);
			}
		}
		
		//const send_url = "https://message.jsrates.com/api/send-email";
        const send_url = "/api/send-email";
        const send_options = {
            "method": "POST",
            "headers": { "x-email-send-token": "from_web" },
            "body": formdata
        };
		
		fetch(send_url,send_options)
		.then(response => response.text())
		.then(data =>{
			console.log(data);
			setLoading(false);
            if(data === "Message Sent!"){
                setStatusMessage('Message sent!');
            }else{
                setStatusMessage(`Error: Failed to send message.`);
            }
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
            setStatusMessage(`Error: Failed to send message.`);
		});
    }

    return (
        <Modal
            size='medium'
            open={active}
            onClose={onClose}
            title="Contact support"
            primaryAction={{
                content: 'Submit',
                onAction: handleSubmit,
                loading:loading,
                disabled: loading
            }}
            secondaryActions={{
                content:"Cancel",
                onAction:onClose
            }}
        >
            <Modal.Section flush>
                <Box padding="400">
                    <BlockStack gap="500">

                        {statusMessage && (
                            <Banner tone={statusMessage.startsWith('Error') ? 'critical' : 'success'}>
                                {statusMessage}
                            </Banner>
                        )}

                        <TextField
                            value={email}
                            onChange={handleEmailChange}
                            label="Email"
                            type="email"
                            error={emailError}
                            autoComplete="email"
                            disabled={loading}
                        />

                        <TextField
                            value={subject}
                            onChange={handleSubjectChange}
                            label="Subject"
                            type="text"
                            error={subjectError}
                            disabled={loading}                
                        />

                        <div>
                            <div style={{"margin-bottom":"var(--p-space-200)"}}>
                                <Text variant="bodyMd" as="p" >
                                    Description
                                </Text>
                            </div>                            
                            <ReactQuill theme="snow" placeholder="Write your message" value={quillValue} onChange={setQuillValue} />
                        </div>

                        <DropZone accept=".gif, .jpg, .png" onDrop={handleDropZoneDrop} variableHeight disabled={loading}>
                            {fileUpload}                           
                        </DropZone>

                        {uploadedFiles}
                        
                    </BlockStack>

                </Box> 
            </Modal.Section>
        </Modal>
    );
};

export default ContactModal;