import {Modal} from '@shopify/polaris';
import {ExternalIcon} from '@shopify/polaris-icons';

const VideoModal = ({ active, onClose, videoUrl }) => {
    return (
        <Modal
            size='large'
            open={active}
            onClose={onClose}
            title="Learn how JsRates works"
            primaryAction={{
                content: 'Close',
                onAction: onClose
            }}
            secondaryActions={{
                content:"View content",
                icon:ExternalIcon,
                onAction:() => { window.open("https://help.jsrates.com/article/how-it-works","_blank")}
            }}
        >
            <Modal.Section flush>
                <iframe
                    width="100%"
                    height="551"
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    title="How it works - JsRates App Tutorial"
                    
                ></iframe>
            </Modal.Section>
        </Modal>
    );
};

export default VideoModal;