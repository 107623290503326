
import { useNavigate } from 'react-router-dom';
import {Page, Layout, CalloutCard, MediaCard,VideoThumbnail, Text, TextField, Button,Card} from '@shopify/polaris';
import {ExternalIcon} from '@shopify/polaris-icons';
import {useState, useCallback, useContext} from "react";
import { DataContext } from "./DataContext";
import VideoModal from "./components/VideoModal";
import TextCard from './components/TextCard';
import ContactModal from './components/ContactModal';

function Home() {
    const { shopName,setShopName,reportDataLoaded } = useContext(DataContext);
    const [textFieldValue, setTextFieldValue] = useState(shopName);



    const handleTextFieldChange = useCallback(
        (value) => setTextFieldValue(value),
        [],
    );

    const handleShopNameClick = useCallback(
        () => {
           
            setShopName(textFieldValue)
            console.log("shop_name",shopName)
            
        },
        [shopName,textFieldValue,setShopName],
    );

    const handleClearButtonClick = useCallback(() => setTextFieldValue(''), []);

    const navigate = useNavigate(); // Create the navigate function

    const videoUrl = "https://www.youtube.com/embed/gDat4TmpEzY";
    const [videoModalActive, setVideoModalActive] = useState(false);    
    const [contactModalActive, setContactModalActive] = useState(false);

    const handleVideoModalChange = () => {
        setVideoModalActive(!videoModalActive);
    };

    const handleContactModalChange = () => {
        setContactModalActive(!contactModalActive);
    };

    const launchEditor = () => {
        navigate("/editor")
    };

    return (
        <Page title="Welcome to JsRates">           
            <Layout>

                <Layout.Section>
                    <Card>                   
                        <TextField
                            label="Shop name"
                            type="text"
                            value={textFieldValue}
                            onChange={handleTextFieldChange}
                            autoComplete="off"
                            clearButton
                            onClearButtonClick={handleClearButtonClick}
                            helpText={shopName?reportDataLoaded?"Data loaded":"Loading...":""}
                            connectedRight={<Button onClick={handleShopNameClick} loading={shopName&&!reportDataLoaded}>Submit</Button>}
                        />
                    </Card>                  
                </Layout.Section>
                               

                <Layout.Section variant='oneHalf'>
                    <TextCard 
                        title="What is JsRates?"
                        content ="JsRates enables easy configuration of shipping rates with Javascript. It includes AI-assisted code generation, debugging, validation, and testing tools. The platform supports integration with top online retailers and courier services, offering functions to improve cart data, access real-time shipping rates, and connect with various platforms through dedicated API."
                        url = "https://help.jsrates.com/article/quick-overview"
                        button_text="Learn more"
                    />                    
                </Layout.Section>

                <Layout.Section variant='oneHalf'>               
                    <CalloutCard 
                        title="Need help with shipping setup?"
                        illustration='https://app.jsrates.com/assets/img/support-callout-icon.png'
                        primaryAction={{content: 'Contact support', onAction:handleContactModalChange} }
                    >
                        <Text as="p" tone="subdued" variant="bodyMd">If you have any questions or need help with setting up your shipping rates, please feel free to reach out. We offer FREE setup service. Our team is here to provide the assistance you need to ensure your shipping configurations are tailored to your business needs and set up effectively.</Text>
                    </CalloutCard>                 
                </Layout.Section>

                
                <Layout.Section >                
                    <CalloutCard 
                        title="Write your code"
                        illustration='https://app.jsrates.com/assets/img/editor-callout-icon.png'
                        primaryAction={{content: 'Launch Code Editor', onAction:launchEditor} }
                    >
                        <Text as="p" tone="subdued" variant="bodyMd">Start by creating custom JavaScript shipping rules with JsRates. Use our AI-assisted code generators or our intuitive interface to develop tailored solutions for your specific business requirements.</Text>
                    </CalloutCard>              
                </Layout.Section>

                <Layout.Section>
                    <MediaCard
                        title="Learn how JsRates works"
                        primaryAction={{
                        content: 'Go to tutorial',
                        onAction: () => { window.open("https://help.jsrates.com/article/how-it-works","_blank")},
                        icon:ExternalIcon
                        }}
                        description="This quick tutorial provides an overview of how JsRates works. Please review it and watch the accompanying video to understand how to utilize JsRates effectively."
                        size="small"
                    >
                        <VideoThumbnail
                            videoLength={11.32}
                            thumbnailUrl="https://cdn.shopify.com/s/files/1/0793/6458/7830/files/how-it-works-thumbnail.png?width=1850"
                            onClick={handleVideoModalChange}
                        />

                    </MediaCard>
                </Layout.Section>

                <Layout.Section variant='oneHalf'>
                    <TextCard 
                        title="AI tools"
                        content ="JsRates boosts your coding experience with AI tools that specialize in generating, explaining, and completing code, aiding in writing and comprehending JavaScript for custom shipping rules."
                        url = "https://help.jsrates.com/article/quick-overview"
                        button_text="Learn more"
                    />           
                </Layout.Section>

                <Layout.Section variant='oneHalf'>
                    <TextCard 
                        title="Built-in modules"
                        content ="Easily integrate real-time shipping rates from global platforms using JsRates' built-in modules, designed to help you implement shipping rate scripts efficiently."
                        url = "https://help.jsrates.com/article/quick-overview"
                        button_text="Learn more"
                    />           
                </Layout.Section>

            </Layout>
                          
     
            <VideoModal
                active={videoModalActive}
                onClose={handleVideoModalChange}
                videoUrl={videoUrl}
            />
            <ContactModal
                active={contactModalActive}
                onClose={handleContactModalChange}
            />
        </Page>
    );
}

export default Home;