import {useContext, useState, useCallback, useRef} from "react";
import { DataContext } from "../DataContext";
import { AreaChart, Area,XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { Card, Bleed, Spinner, InlineStack, Text, BlockStack,Popover,ActionList, Button, Layout, Box, SkeletonDisplayText} from "@shopify/polaris";
import {CalendarIcon} from '@shopify/polaris-icons';
import { timeFormat} from 'd3';
import {processedRequestDurationData, exportImage, savetoCSV} from "../assets/js/tools";

const formatDate = timeFormat('%b %d');
const formatDateTootip = timeFormat('%b %d, %H:%M');


const calculateTicks = (data, maxTicks) => {
  const tickInterval = Math.ceil(data.length / maxTicks);
  return data.filter((_, index) => index % tickInterval === 0).map(d => d.hour);
}

export default function RequestDurationChart(){
    const { reportData} = useContext(DataContext);
    const svgRef = useRef(null);
    const [dateRange, setDateRange] = useState("7-days");
    const [dateRangeButton, setDateRangeButton] = useState("Last 7 days");
    const [popoverRangeActive, setPopoverRangeActive] = useState(false);
    const [popoverExportActive, setPopoverExportActive] = useState(false);

    const togglePopoverRangeActive = useCallback(
      () => setPopoverRangeActive((popoverRangeActive) => !popoverRangeActive),
      [],
    );
    const togglePopoverExportActive = useCallback(
        () => setPopoverExportActive((popoverExportActive) => !popoverExportActive),
        [],
    );

    const handleDateChange = (date_range,buttonText) => {
        setDateRange(date_range);
        setDateRangeButton(buttonText);
        setPopoverRangeActive((popoverRangeActive) => !popoverRangeActive)
    }

    const dateActivator = (
        <Button onClick={togglePopoverRangeActive} icon={CalendarIcon}>
          {dateRangeButton}
        </Button>
      );
  
      const exportActivator = (
        <Button onClick={togglePopoverExportActive} variant="secondary" disclosure>
          Export
        </Button>
      );

    const report_loaded = Object.keys(reportData).length>0;
    const data = report_loaded?reportData.formattedData:[];

    const processObj= processedRequestDurationData(data,dateRange)

    // Filter your data
    const processData = report_loaded?processObj.processData:[];
    const ticks = calculateTicks(processData, 10);  // Get 10 ticks, for example

    const background = report_loaded?"var(--p-color-bg-surface)":"var(--p-color-bg)";
    
    return (
      <>
      <Layout.Section variant="oneHalf">
        <Card roundedAbove="sm">
          <div style={{height:"85px",maxHeight:"85px"}}>
            <Text as="h2" variant="headingSm">
              Average duration
            </Text>
            <Box paddingBlockStart="200">
            {processData.length > 0 ? 
              <InlineStack align="start" blockAlign="center">
                <Text as="p" variant="headingXl">
                  {processObj.average_duration?processObj.average_duration:0}
                </Text>
                <Text as="p" variant="headingMd">&nbsp;s</Text>
              </InlineStack>
              :
              <SkeletonDisplayText></SkeletonDisplayText>
            }
            </Box>
          </div>
        </Card>
      </Layout.Section>
      <Layout.Section variant="oneHalf">
        <Card roundedAbove="sm">
          <div style={{height:"85px",maxHeight:"85px"}}>
            <Text as="h2" variant="headingSm">
              Request duration
            </Text>
            <Box paddingBlockStart="200">
                <Text as="p" variant="bodyMd">The request duration report shows the 50th, 75th and 99th percentile the request duration in seconds for request in every one hour.</Text>
            </Box>
          </div>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <BlockStack gap="400">
          <div style={{height: '25px'}}>
              <InlineStack align="space-between">
              <Popover
                  active={popoverRangeActive}
                  activator={dateActivator}
                  autofocusTarget="first-node"
                  onClose={togglePopoverRangeActive}
              >
                  <ActionList
                  actionRole="menuitem"
                  items={[
                      {content: 'Today',onAction:()=>handleDateChange("today","Today")}, 
                      {content: 'Yesterday',onAction:()=>handleDateChange("yesterday","Yesterday")},
                      {content: 'Last 7 days',onAction:()=>handleDateChange("7-days","Last 7 days")},
                      {content: 'Last 30 days',onAction:()=>handleDateChange("30-days","Last 30 days")},
                      {content: 'Week to date',onAction:()=>handleDateChange("week-to-date", "Week to date")},
                      {content: 'Month to date',onAction:()=>handleDateChange("month-to-date", "Month to date")}
                  ]}
                  />
              </Popover>
              <Popover
                  active={popoverExportActive}
                  activator={exportActivator}
                  autofocusTarget="first-node"
                  onClose={togglePopoverExportActive}
              >
                  <ActionList
                  actionRole="menuitem"
                  items={[
                    {content: 'Export to CSV', onAction:()=>savetoCSV(data, dateRange, "Request Duration")}, 
                    {content: 'Export to JPEG', onAction:()=>exportImage(svgRef.current,"jpeg")},
                    {content: 'Export to PNG', onAction:()=>exportImage(svgRef.current,"png")},
                    {content: 'Export to SVG', onAction:()=>exportImage(svgRef.current,"svg")}]}
                  />
              </Popover>
              </InlineStack>
          </div>
        
          <Card>
            <Bleed marginInline="400">
              <div style={{height:"40px",backgroundColor:background}}>
              {processData.length > 0 &&  
                <InlineStack align="space-evenly" wrap={false} blockAlign="start">
                  
                  <BlockStack>
                    <Text as="p" variant="bodyXs">50th percentile</Text>
                    <Text as="h3" variant="headingXs">{processObj.total_percentile50}</Text>
                  </BlockStack>
                  <BlockStack>
                    <Text as="p" variant="bodyXs">75th percentile</Text>
                    <Text as="h3" variant="headingXs">{processObj.total_percentile75}</Text>
                  </BlockStack>
                  <BlockStack>
                    <Text as="p" variant="bodyXs">99th percentile</Text>
                    <Text as="h3" variant="headingXs">{processObj.total_percentile99}</Text>
                  </BlockStack>
                </InlineStack>           
              }
              </div>
            
            
              
            <div style={{height:"200px",backgroundColor:background, display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
              {processData.length === 0?
                <Spinner accessibilityLabel="Reports loading" size="large"/>:
                <ResponsiveContainer width="100%" height="100%" ref={svgRef}>
                  <AreaChart
                    title="Request duration"
                    desc="The request duration report shows the 50th, 75th and 99th percentile the request duration in seconds for request in every one hour."                    
                    width={764}
                    height={180}
                    data={processData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                    barCategoryGap="20%" minBarGap={2}
                  >
                    <defs>
                      <linearGradient id="color99" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#0A97D5" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="#0A97D5" stopOpacity={0.25}/>
                      </linearGradient>
                      <linearGradient id="color75" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ffab00" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="#ffab00" stopOpacity={0.25}/>
                      </linearGradient>
                      <linearGradient id="color50" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgb(106,66,233)" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="rgb(106,66,233)" stopOpacity={0.25}/>
                      </linearGradient>
                    </defs>
                    <Legend verticalAlign="bottom" align="right" />
                    <CartesianGrid vertical={false} strokeDasharray="0.5" stroke="#eeeeef"/>
                    
                    <XAxis dataKey="hour" ticks={ticks} tickFormatter={(tick) => formatDate(new Date(tick))} axisLine={false} fontSize={12} tick={{fill:"#70707b",fontSize:"12"}} />
                    <YAxis axisLine={false} tickLine={false} fontSize={12} tick={{fill:"#70707b",fontSize:"12"}} />
                    <Tooltip labelFormatter={label => formatDateTootip(new Date(label))} formatter={(value) => `${parseFloat(value).toFixed(2)}`}/>

                    <Area dataKey="percentile99" type="monotone" stroke="#0A97D5" fill="url(#color99)" fillOpacity={1} strokeWidth={2}/>
                    <Area dataKey="percentile75" type="monotone" stroke="#ffab00" fill="url(#color75)" fillOpacity={1} strokeWidth={2}/>
                    <Area dataKey="percentile50" type="monotone" stroke="rgb(106,66,233)" fill="url(#color50)" fillOpacity={1} strokeWidth={2}/>

                  </AreaChart>
                </ResponsiveContainer>
            
              }
            </div>
            </Bleed>
          </Card>
        </BlockStack>
      </Layout.Section>
      </>
    );
  
}
