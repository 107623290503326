import { Text,ResourceList, LegacyCard,Icon,TextField } from '@shopify/polaris';
import {BookOpenIcon, SearchIcon } from '@shopify/polaris-icons'; 
import {useState} from 'react';
import algoliasearch from 'algoliasearch/lite';
import parse from 'html-react-parser'; 

// Setup Algolia
const client = algoliasearch('R1DNJAYEJV', '293891cf705f3e302ae4cc2158c0303c');
const index = client.initIndex('help_docs');

function SearchList({changeTopic}) {
    const [query, setQuery] = useState('');
    const [items, setItems] = useState([]);
  
    const performSearch = async (query) => {
      if (query.trim() === '') {
        setItems([]);
        return;
      }
  
      const { hits } = await index.search(query);
      const filteredItems = hits.map(hit =>{
            return {
                content:hit._snippetResult.content.value,
                objectID:hit.objectID,
                title:hit.description
            }
        })
      setItems(filteredItems); 
    };

    const handleClear = () => {
        setQuery('');
        setItems([]);  
    };

    const openDocModal = (help_title,topic) => {
        changeTopic({title:topic,handle:help_title});
    }
  
    return (
      <LegacyCard>
        <LegacyCard.Section>
            <TextField
                value={query}
                onChange={(newValue) => {setQuery(newValue);performSearch(newValue)}}
                placeholder="Search articles..."
                clearButton
                onClearButtonClick={handleClear}
                onBlur={() => performSearch(query)}
                prefix={<Icon source={SearchIcon} color="inkLighter" />}
            />
            {items.length > 0 && (
                <ResourceList
                    items={items}
                    renderItem={(item) => (
                        <ResourceList.Item
                            id={item.objectID}  // Use Algolia's unique objectID
                            accessibilityLabel={`View details for ${item.title}`}
                            media={<Icon source={BookOpenIcon} color="base" />}
                            onClick={()=>openDocModal(item.objectID, item.title.trim())}
                        >
                            <div className='article-row'>
                                <Text as="h3" variant="bodyMd" fontWeight='semibold'>
                                    {item.title.trim()}
                                </Text>
                                <Text as="p" variant='bodySm'>
                                    {parse(item.content)}
                                </Text>
                            </div>
                            
                        </ResourceList.Item>
                    )}
                    resourceName={{singular: 'article', plural: 'articles'}}
                />
            )}
        </LegacyCard.Section>
      </LegacyCard>
    );
  }
  
  export default SearchList;