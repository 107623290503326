import {Card,Text,BlockStack,InlineStack,ButtonGroup,Button} from '@shopify/polaris';
import {ExternalIcon} from '@shopify/polaris-icons';

const TextCard = ({title,content,url,button_text}) => {
    return (
        <Card roundedAbove="sm">
            <BlockStack gap="200">
                <Text as="h2" variant="headingSm">
                {title}
                </Text>
                <BlockStack gap="200">
                    <Text as="p" tone="subdued">
                    {content}
                    </Text>                           
                </BlockStack>
                <InlineStack align="start">
                    <ButtonGroup>                                
                        <Button
                            icon={ExternalIcon}
                            variant="secondary"
                            onClick={() => { window.open(url,"_blank")}}
                            accessibilityLabel={button_text}
                        >
                            {button_text}
                        </Button>
                    </ButtonGroup>
                </InlineStack>
            </BlockStack>
        </Card> 
    )
}

export default TextCard;