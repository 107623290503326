import {useContext, useCallback} from "react";
import { DataContext } from "./DataContext";
import {Page, Button,Layout, Card} from '@shopify/polaris';
import {
  ImportIcon,
  ExportIcon,
  PlayIcon,
  MagicIcon,
  SearchRecentIcon,
  SandboxIcon,
  MaximizeIcon,
  ColorIcon,
  BugIcon,
  RefreshIcon,
  DeleteIcon,
} from '@shopify/polaris-icons';
import "./assets/styles/editor.css";
import CodeEditor from './components/CodeEditor';
import FilesTab from './components/FilesTab';
import CodeOutput from "./components/CodeOutput";
import LogsTable from "./components/LogsTable";
import LogsModal from "./components/LogsModal";
import ProblemsTable from "./components/ProblemsTable";

function Editor() {
  const {activeOutput,setActiveOutput,codeOutputStatus,setCodeOutputStatus,setLogsDataLoaded,setProblemsDataLoaded,currentFileName} = useContext(DataContext);
  
  const renderLogsTable = useCallback(()=>{
    setCodeOutputStatus(true); //to open the output wrapper
    setLogsDataLoaded(false);
    setActiveOutput({
      title:"Logs",
      component:<LogsTable />
    })
  },[setActiveOutput,setCodeOutputStatus,setLogsDataLoaded]);

  const renderProblemsTable = useCallback(()=>{
    setCodeOutputStatus(true); //to open the output wrapper
    setProblemsDataLoaded(false);
    setActiveOutput({
      title:`problems in ${currentFileName}.js`,
      component:<ProblemsTable active_file={currentFileName}/>
    })
  },[setActiveOutput,setCodeOutputStatus,setProblemsDataLoaded,currentFileName])

  return (
    <Page
      title="Code editor" 
      fullWidth
      actionGroups={[        
        {
          title: 'More actions',
          actions: [
            {content: 'Run',icon:PlayIcon},
            {content: 'Generate',icon:MagicIcon},
            {
              content: 'Logs',
              icon:SearchRecentIcon,
              onAction:renderLogsTable
            },
            {content: 'Test data',icon:SandboxIcon},
            {content: 'Import',icon:ImportIcon},
            {content: 'Expand',icon:MaximizeIcon},
            {content: 'Theme',icon:ColorIcon},
            {
              content: 'Problems',
              icon:BugIcon,
              onAction:renderProblemsTable
            },
            {content: 'Delete file',icon:DeleteIcon,destructive: true},
            {content: 'Reload file',icon:RefreshIcon},
            {content: 'Download file',icon:ExportIcon},
          ],
        }      
      ]}
      primaryAction={<Button variant="primary" disabled>Save</Button>}
      >
      
      <Layout>
          <Layout.Section fullWidth>
            <Card padding="0">
              <FilesTab />
              <CodeEditor />                            
            </Card>
            {codeOutputStatus && 
              <CodeOutput title={activeOutput.title}>
                {activeOutput.component}
              </CodeOutput>
             }              
                
          </Layout.Section>
      </Layout>
      <LogsModal />
      
    </Page>
  );
}

export default Editor;
