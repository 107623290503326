import {useState} from "react";
import {Page,Button,Layout} from '@shopify/polaris';
import {QuestionCircleIcon} from '@shopify/polaris-icons';
import ContactModal from './components/ContactModal';
import DocsModal from "./components/DocsModal";
import SearchList from './components/SearchList';
import HelpContent from "./components/HelpContent";

function Help() {
  const [contactModalActive, setContactModalActive] = useState(false);
  const [docsModalActive, setDocsModalActive] = useState(false);
  const [topics, setTopics] = useState({title:"",handle:""});

  const handleContactModalChange = () => {
      setContactModalActive(!contactModalActive);
  };

  const handleDocsModalChange = () => {
    setDocsModalActive(!docsModalActive);
  };

  const handleDocsTopic = (topic) =>{
    setTopics(topic);
    handleDocsModalChange();
  }

  return (
    <Page title="Help docs" secondaryActions={<Button icon={QuestionCircleIcon} onClick={handleContactModalChange}>Contact support</Button>}>
     <Layout>
      <Layout.Section>
        <SearchList changeTopic={handleDocsTopic}/>
      </Layout.Section>        
     </Layout>

     <HelpContent changeTopic={handleDocsTopic}/>

     <ContactModal
          active={contactModalActive}
          onClose={handleContactModalChange}
      />
      <DocsModal
          active={docsModalActive}
          onClose={handleDocsModalChange}
          topics={topics}
      />
    </Page>
  );
}

export default Help;
