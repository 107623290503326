import React from 'react';
import { Routes, Route, useNavigate,useLocation } from "react-router-dom";
import { DataProvider } from './DataContext';
import './App.css';
import {Frame,Navigation, TopBar, ActionList} from '@shopify/polaris';
import {HomeIcon, ChartPopularIcon, CodeIcon, QuestionCircleIcon, SettingsIcon, BillIcon} from '@shopify/polaris-icons';
import {useState, useCallback,useRef} from 'react';
import Analytics from "./Analytics";
import Home from './Home';
import Editor from './Editor';
import Help from "./Help";
import Settings from "./Settings";
import Plans from "./Plans"


function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const skipToContentRef = useRef(null);

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const userMenuActions = [
    {
      items: [{content: 'Community forums'}],
    },
  ];

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const searchResultsMarkup = (
    <ActionList
      items={[{content: 'Shopify help center'}, {content: 'Community forums'}]}
    />
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name="Dharma"
      detail= "JsRates"
      initials="D"
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
        <Navigation.Section
          items={[
            {
              label: 'Home',
              icon: HomeIcon,
              onClick: () => navigate('/'),
              selected: location.pathname === '/',
            },
            {
              excludePaths: ['#'],
              label: 'Editor',
              icon: CodeIcon,
              onClick: () => navigate('/editor'),
              selected: location.pathname === '/editor',
            },
            {
              excludePaths: ['#'],
              label: 'Analytics',
              icon: ChartPopularIcon,
              onClick: () => navigate('/analytics'),
              selected: location.pathname === '/analytics',
            },
            {
              excludePaths: ['#'],
              label: 'Settings',
              icon: SettingsIcon,
              onClick: () => navigate('/settings'),
              selected: location.pathname === '/settings',
            },
            {
              excludePaths: ['#'],
              label: 'Plans',
              icon: BillIcon,
              onClick: () => navigate('/plans'),
              selected: location.pathname === '/plans',
            },
            {
              excludePaths: ['#'],
              label: 'Help',
              icon: QuestionCircleIcon,
              onClick: () => navigate('/help'),
              selected: location.pathname === '/help',
            }
            
          ]}
        />
    </Navigation>
  );

  const logo = {
    width: 42,
    topBarSource:
      'https://cdn.shopify.com/app-store/listing_images/7c2e622571551a20389aefd6aaaa9aa7/icon/CJaxrMyX7YIDEAE=.png',
    contextualSaveBarSource:
      'https://cdn.shopify.com/app-store/listing_images/7c2e622571551a20389aefd6aaaa9aa7/icon/CJaxrMyX7YIDEAE=.png',
    accessibilityLabel: 'JsRates',
  };

  return (

      <Frame 
        logo = {logo}
        navigation={navigationMarkup} 
        topBar={topBarMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
      >
         <DataProvider>
            <Routes>           
                <Route path="/" element={<Home />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/plans" element={<Plans />} />
                <Route path="/help" element={<Help />} />            
            </Routes>
          </DataProvider>
        
      </Frame>
      

  );
}

export default App;
