import {useContext, useState, useCallback, useRef} from "react";
import { DataContext } from "../DataContext";
import { AreaChart, Area,XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { Card, Bleed, Spinner, InlineStack, Text, BlockStack,Popover,ActionList, Button, Layout, Box, SkeletonDisplayText} from "@shopify/polaris";
import {CalendarIcon} from '@shopify/polaris-icons';
import { timeFormat} from 'd3';
import {processedRequestStatusData, exportImage, savetoCSV} from "../assets/js/tools";

const formatDate = timeFormat('%b %d');
const formatDateTootip = timeFormat('%b %d, %H:%M');


const calculateTicks = (data, maxTicks) => {
  const tickInterval = Math.ceil(data.length / maxTicks);
  return data.filter((_, index) => index % tickInterval === 0).map(d => d.hour);
}

export default function RequestStatusChart(){
    const { reportData} = useContext(DataContext);
    const svgRef = useRef(null);
    const [dateRange, setDateRange] = useState("7-days");
    const [dateRangeButton, setDateRangeButton] = useState("Last 7 days");
    const [dataSource, setDataSource] = useState("all");
    const [dataSourceButton, setDataSourceButton] = useState("All");
    const [lineStroke, setLineStroke] = useState("");
    const [popoverRangeActive, setPopoverRangeActive] = useState(false);
    const [popoverSourceActive, setPopoverSourceActive] = useState(false);
    const [popoverExportActive, setPopoverExportActive] = useState(false);

    const togglePopoverRangeActive = useCallback(
      () => setPopoverRangeActive((popoverRangeActive) => !popoverRangeActive),
      [],
    );
    const togglePopoverSourceActive = useCallback(
        () => setPopoverSourceActive((popoverSourceActive) => !popoverSourceActive),
        [],
    );
    const togglePopoverExportActive = useCallback(
        () => setPopoverExportActive((popoverExportActive) => !popoverExportActive),
        [],
    );

    const dateActivator = (
        <Button onClick={togglePopoverRangeActive} icon={CalendarIcon}>
            {dateRangeButton}
        </Button>
    );

    const sourceActivator = (
        <Button onClick={togglePopoverSourceActive}>
            {dataSourceButton}
        </Button>
    );
  
    const exportActivator = (
    <Button onClick={togglePopoverExportActive} variant="secondary" disclosure>
        Export
    </Button>
    );

    

    const report_loaded = Object.keys(reportData).length>0;
    const data = report_loaded?reportData.formattedReq:[];

    const processObj= processedRequestStatusData(data,dateRange,dataSource);

    const handleDateChange = (date_range,buttonText) => {
      setDateRange(date_range);
      setDateRangeButton(buttonText);
      setPopoverRangeActive((popoverRangeActive) => !popoverRangeActive);
    }

    const handleSourceChange = (source, button_text) => {
        setDataSource(source);
        setDataSourceButton(button_text);
        setPopoverSourceActive((popoverSourceActive) => !popoverSourceActive);
        if(source === "checkout"){
            setLineStroke("#0A97D5");
        }else if(source === "api"){
            setLineStroke("#ffab00");
        }else if(source === "editor"){
            setLineStroke("rgb(106,66,233)");
        }
    }

    // Filter your data
    const processData = report_loaded?processObj.processData:[];
    const ticks = calculateTicks(processData, 10);  // Get 10 ticks, for example

    const background = report_loaded?"var(--p-color-bg-surface)":"var(--p-color-bg)";
    
    return (
      <>
      <Layout.Section variant="oneHalf">
        <Card roundedAbove="sm">
          <div style={{height:"85px",maxHeight:"85px"}}>
            <Text as="h2" variant="headingSm">
              Total request
            </Text>
            <Box paddingBlockStart="200">
            {processData.length > 0 ? 
              <InlineStack align="start" blockAlign="center">
                <Text as="p" variant="headingXl">
                  {processObj.totalRequest?processObj.totalRequest:0}
                </Text>
              </InlineStack>
              :
              <SkeletonDisplayText></SkeletonDisplayText>
            }
            </Box>
          </div>
        </Card>
      </Layout.Section>
      <Layout.Section variant="oneHalf">
        <Card roundedAbove="sm">
          <div style={{height:"85px",maxHeight:"85px"}}>
            <Text as="h2" variant="headingSm">
              Request status
            </Text>
            <Box paddingBlockStart="200">
                <Text as="p" variant="bodyMd">The request status report shows the number of requests processed and their status in every one hour span.</Text>
            </Box>
          </div>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <BlockStack gap="400">
          <div style={{height: '25px'}}>
              <InlineStack align="space-between">
                <InlineStack gap="200">
                    <Popover
                        active={popoverRangeActive}
                        activator={dateActivator}
                        autofocusTarget="first-node"
                        onClose={togglePopoverRangeActive}
                    >
                        <ActionList
                        actionRole="menuitem"
                        items={[
                            {content: 'Today',onAction:()=>handleDateChange("today","Today")}, 
                            {content: 'Yesterday',onAction:()=>handleDateChange("yesterday","Yesterday")},
                            {content: 'Last 7 days',onAction:()=>handleDateChange("7-days","Last 7 days")},
                            {content: 'Last 30 days',onAction:()=>handleDateChange("30-days","Last 30 days")},
                            {content: 'Week to date',onAction:()=>handleDateChange("week-to-date", "Week to date")},
                            {content: 'Month to date',onAction:()=>handleDateChange("month-to-date", "Month to date")}
                        ]}
                        />
                    </Popover>
                    <Popover
                        active={popoverSourceActive}
                        activator={sourceActivator}
                        autofocusTarget="first-node"
                        onClose={togglePopoverSourceActive}
                    >
                        <ActionList
                        actionRole="menuitem"
                        items={[
                            {content: 'All',onAction:()=>handleSourceChange("all","All")}, 
                            {content: 'Checkout',onAction:()=>handleSourceChange("checkout","Checkout")},
                            {content: 'API',onAction:()=>handleSourceChange("api","API")},
                            {content: 'Editor',onAction:()=>handleSourceChange("editor","Editor")}
                        ]}
                        />
                    </Popover>
                </InlineStack>
                <Popover
                    active={popoverExportActive}
                    activator={exportActivator}
                    autofocusTarget="first-node"
                    onClose={togglePopoverExportActive}
                >
                    <ActionList
                    actionRole="menuitem"
                    items={[
                        {content: 'Export to CSV', onAction:()=>savetoCSV(data, dateRange, "Request Duration")}, 
                        {content: 'Export to JPEG', onAction:()=>exportImage(svgRef.current,"jpeg")},
                        {content: 'Export to PNG', onAction:()=>exportImage(svgRef.current,"png")},
                        {content: 'Export to SVG', onAction:()=>exportImage(svgRef.current,"svg")}]}
                    />
                </Popover>
              </InlineStack>
          </div>
        
          <Card>
            <Bleed marginInline="400">
              <div style={{height:"40px",backgroundColor:background}}>
              {processData.length > 0 &&  
                <InlineStack align="space-evenly" wrap={false} blockAlign="start">
                  <BlockStack>
                    <Text as="p" variant="bodyXs">success</Text>
                    <Text as="h3" variant="headingXs">{processObj.totalSuccess}</Text>
                  </BlockStack>
                  <BlockStack>
                    <Text as="p" variant="bodyXs">error</Text>
                    <Text as="h3" variant="headingXs">{processObj.totalError}</Text>
                  </BlockStack>
                  {dataSource === "all"?
                    <>
                    <BlockStack>
                        <Text as="p" variant="bodyXs">checkout</Text>
                        <Text as="h3" variant="headingXs">{processObj.totalShopify}</Text>
                    </BlockStack>
                    <BlockStack>
                        <Text as="p" variant="bodyXs">api</Text>
                        <Text as="h3" variant="headingXs">{processObj.totalApi}</Text>
                    </BlockStack>
                    <BlockStack>
                        <Text as="p" variant="bodyXs">editor</Text>
                        <Text as="h3" variant="headingXs">{processObj.totalEditor}</Text>
                    </BlockStack>
                    </>
                    :
                    <BlockStack>
                        <Text as="p" variant="bodyXs">{dataSource}</Text>
                        <Text as="h3" variant="headingXs">{processObj.totalRequest?processObj.totalRequest:0}</Text>
                    </BlockStack>
                }
                </InlineStack>           
              }
              </div>
            
            
              
            <div style={{height:"200px",backgroundColor:background, display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
              {processData.length === 0?
                <Spinner accessibilityLabel="Reports loading" size="large"/>:
                <ResponsiveContainer width="100%" height="100%" ref={svgRef}>
                  <AreaChart
                    width={764}
                    height={180}
                    data={processData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                    barCategoryGap="20%" minBarGap={2}
                  >
                    <defs>
                      <linearGradient id="checkout" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#0A97D5" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="#0A97D5" stopOpacity={0.25}/>
                      </linearGradient>
                      <linearGradient id="api" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ffab00" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="#ffab00" stopOpacity={0.25}/>
                      </linearGradient>
                      <linearGradient id="editor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgb(106,66,233)" stopOpacity={0.25}/>
                        <stop offset="95%" stopColor="rgb(106,66,233)" stopOpacity={0.25}/>
                      </linearGradient>
                    </defs>
                    <Legend verticalAlign="bottom" align="right" />
                    <CartesianGrid vertical={false} strokeDasharray="0.5" stroke="#eeeeef"/>
                    
                    <XAxis dataKey="hour" ticks={ticks} tickFormatter={(tick) => formatDate(new Date(tick))} axisLine={false} fontSize={12} tick={{fill:"#70707b",fontSize:"12"}} />
                    <YAxis axisLine={false} tickLine={false} fontSize={12} tick={{fill:"#70707b",fontSize:"12"}} />
                    <Tooltip labelFormatter={label => formatDateTootip(new Date(label))} />
                    {dataSource === "all"?
                        <>
                        <Area dataKey="checkout" type="monotone" stroke="#0A97D5" fill="url(#checkout)" fillOpacity={1} strokeWidth={2}/>
                        <Area dataKey="api" type="monotone" stroke="#ffab00" fill="url(#api)" fillOpacity={1} strokeWidth={2}/>
                        <Area dataKey="editor" type="monotone" stroke="rgb(106,66,233)" fill="url(#editor)" fillOpacity={1} strokeWidth={2}/>
                        </>
                        :
                        <Area dataKey={dataSource} type="monotone" stroke={lineStroke} fill={`url(#${dataSource})`} fillOpacity={1} strokeWidth={2}/>
                    }
                    

                  </AreaChart>
                </ResponsiveContainer>
            
              }
            </div>
            </Bleed>
          </Card>
        </BlockStack>
      </Layout.Section>
      </>
    );
  
}
