import {Page, Layout, Divider, Text,FooterHelp,Link} from '@shopify/polaris';
import RequestDurationChart from "./components/RequestDurationChart";
import RequestStatusChart from "./components/RequestStatusChart";
import ShippingRatesChart from "./components/ShippingRatesChart";
import RequesDistributionChart from "./components/RequestDistributionChart";

function Analyticss() {
  return (
    <Page title="Analytics">
      <Layout> 
          <Layout.Section><Text as="h3" variant='headingLg' fontWeight="regular"><span style={{marginLeft:"var(--p-space-200)"}}>Request duration</span></Text></Layout.Section>
          <RequestDurationChart />
          <Layout.Section><Divider /></Layout.Section>
          <Layout.Section><Text as="h3" variant='headingLg' fontWeight="regular"><span style={{marginLeft:"var(--p-space-200)"}}>Request status</span></Text></Layout.Section>
          <RequestStatusChart />
          <Layout.Section><Divider /></Layout.Section>
          <Layout.Section><Text as="h3" variant='headingLg' fontWeight="regular"><span style={{marginLeft:"var(--p-space-200)"}}>Shipping Rates</span></Text></Layout.Section>
          <ShippingRatesChart />
          <Layout.Section><Divider /></Layout.Section>
          <Layout.Section><Text as="h3" variant='headingLg' fontWeight="regular"><span style={{marginLeft:"var(--p-space-200)"}}>Shipping Rates</span></Text></Layout.Section>
          <RequesDistributionChart />
      </Layout>
      <FooterHelp>
        See more about{' '}
        <Link url="https://help.jsrates.com/article/analytics-reports" external>
        Analytics Reports
        </Link>
      </FooterHelp>
           
    </Page>
  );
}

export default Analyticss;

