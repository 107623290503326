import { timeHour, groups,median,quantile,mean,csvFormat,count,max } from 'd3';

export const processReports = (report_logs)=>{
    
    //--- exclude errors from duation and date 
    const durations_error = report_logs.map(obj => {
        if (!("error" in obj)) {
            return obj.duration.replace(" sec", "")
        }

    });

    const date_error = report_logs.map(obj => {
        if (!("error" in obj)) {
            return obj.date
        }
    });


    const result_error = date_error.map((key, index) => [key, durations_error[index]]);
    const data_error = Object.fromEntries(result_error);

    // Convert epoch time to Date objects
    const formattedData = Object.entries(data_error).map(([epoch, duration]) => ({
        date: new Date(parseInt(epoch)),
        duration: +duration
    }));



    //const durations = report_logs.map(obj => obj.duration.replace(" sec", ""));
    const date = report_logs.map(obj => obj.date);

    //const result = date.map((key, index) => [key, durations[index]]);


    const requests = report_logs.map(obj => {
        let res = { editor: 1, shopify: 0, error: 0, api: 0, success: 1 };
        if ("from_shopify" in obj) {
            res.shopify = 1;
            res.editor = 0;
        }

        if ("from_api" in obj) {
            res.api = 1;
            res.editor = 0;
        }
        if ("error" in obj) {
            res.error = 1;
            res.success = 0;
        }

        return res;

    });
    const result_req = date.map((key, index) => [key, requests[index]]);
    const data_req = Object.fromEntries(result_req);
    // Convert epoch time to Date objects
    const formattedReq = Object.entries(data_req).map(([epoch, request]) => ({
        date: new Date(parseInt(epoch)),
        success: +request.success,
        error: +request.error,
        editor: +request.editor,
        shopify: +request.shopify,
        api: +request.api
    }));

    ///////////////////
    const rates = report_logs.map(obj => {
        if ("rates" in obj) {
            if (obj.rates.length === 0) {
                return [{}];
            } else {
                return obj.rates;
            }
        } else {
            return [{}];
        }

    });

    const price = rates.map(obj => {
        let arrs = [];
        let currency = "";
        obj.forEach(arr => {
            if(typeof(arr) === "object" && arr !== null && !Array.isArray(arr)){
                if("total_price" in arr && "currency" in arr){
                    if((arr.total_price !== null || arr.total_price !== "null") && arr.currency !== ""){
                        arrs.push(parseFloat(arr.total_price) / 100);
                        currency = arr.currency;
                    }
                }
            }            
        }
        );

        //return Math.min.apply(Math, arrs)
        const min_price = Math.min.apply(Math, arrs);
        if(min_price !== Infinity && !isNaN(min_price) && currency !== ""){
            return { price: min_price, currency: currency }
        }else{
            return { price: NaN, currency: currency }
        }
        
    });


    const result_price = date.map((key, index) => [key, { price: price[index].price, currency: price[index].currency }]);
    const data_price = Object.fromEntries(result_price);


    // Convert epoch time to Date objects
    const formattedPrice = Object.entries(data_price).map(([epoch, price]) => ({
        date: new Date(parseInt(epoch)),
        price: +price.price,
        currency: price.currency
    }));

    const filteredPriceData = formattedPrice.filter((value) => !isNaN(value.price));

    const currencyCounts = {};

    for (const item of filteredPriceData) {
        const currency = item.currency;
        if (currencyCounts[currency]) {
            currencyCounts[currency] += 1;
        } else {
            currencyCounts[currency] = 1;
        }
    }

    const currencyData = Object.entries(currencyCounts).map(([currency, count]) => {
        return { currency, count };
    }).sort((a, b) => b.count - a.count);

    // let initCurrency = "";
    // if (currencyData.length > 0) {
    //     initCurrency = currencyData[0].currency;
    // }

    //const init_filteredPriceData = filteredPriceData.filter(value => value.currency == initCurrency);

    return {
        formattedData:formattedData,
        filteredPriceData:filteredPriceData,
        formattedReq:formattedReq,
        currencyData:currencyData
    }

}

export const processedRequestDurationData = (formattedData,duration) => {
    // Preprocess the data to calculate percentiles for each hour
    const dataByHour = groups(formattedData, d => timeHour.floor(d.date));
  
    const hoursInLastWeek = [];
  
    const processData = [];
  
    // Generate hoursInLastWeek array with percentiles set to zero
    const currentHour = new Date();
    currentHour.setHours(currentHour.getHours() + 1);
  
    function startHour() {
        let day = 1;
        let hours = 1;

        const now = new Date(); // Current date and time
        const currentTimestamp = now.getTime(); // Milliseconds since January 1, 1970
        const currentDayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        const previousMidnightTimestamp = currentDayStart.getTime();

        let durationMilliseconds = 0;
        if (duration === "today") {
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "yesterday") {
            hours = 25;
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "7-days") {
            day = 7;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "30-days") {
            day = 30;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "week-to-date") {
            hours = currentHour.getHours();
            const currentDay = now.getDay(); // Day of the week (0 - Sunday, 1 - Monday, etc.)
            const millisecondsPerDay = 24 * 60 * 60 * 1000;
            const currentWeekMidnightTimestamp = ((currentDay === 0 ? 7 : currentDay) - 0) * millisecondsPerDay - millisecondsPerDay + hours * 60 * 60 * 1000;
            durationMilliseconds = currentWeekMidnightTimestamp;
        } else if (duration === "month-to-date") {
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
            durationMilliseconds = currentTimestamp - startOfMonth.getTime() + day * hours * 60 * 60 * 1000;;

        }
        return new Date(currentHour.getTime() - durationMilliseconds);

    }

    function endHour() {
        if (duration === "yesterday") {
            const now = new Date(); // Current date and time
            return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        } else {
            return currentHour;
        }

    }

    const startDuration = startHour().getTime();
    const endDuration = endHour().getTime();



    for (let i = startDuration; i <= endDuration; i += 60 * 60 * 1000) {
        const hour =timeHour.floor(new Date(i));
        hoursInLastWeek.push(hour);
    }

    let all_durations = [];

    for (const hour of hoursInLastWeek) {
        const matchingData = dataByHour.find(([h]) => h.getTime() === hour.getTime());

        if (matchingData) {
            const durations = matchingData[1].map(d => d.duration);
            const percentile50 = median(durations);
            const percentile75 = quantile(durations, 0.75);
            const percentile99 = quantile(durations, 0.99);

            processData.push({
            hour,
            percentile50,
            percentile75,
            percentile99
            })

            all_durations.push(...durations);
        } else {
        processData.push({
            hour,
            percentile50:0,
            percentile75:0,
            percentile99:0
        })
        }
    }

    let total_percentile50 = "";
    let total_percentile75 = "";
    let total_percentile99 = "";
    let average_duration = "";

    if (all_durations.length > 0) {
        total_percentile50 = median(all_durations).toFixed(2) + " sec";
        total_percentile75 = quantile(all_durations, 0.75).toFixed(2) + " sec";
        total_percentile99 = quantile(all_durations, 0.99).toFixed(2) + " sec";
        average_duration = mean(all_durations).toFixed(2);
    }
    
    return {processData,total_percentile50,total_percentile75,total_percentile99,average_duration};
}

export const processedRequestStatusData = (formattedData,duration,source) => {
    console.log(formattedData)
    // Preprocess the data to calculate percentiles for each hour
    const dataByHour = groups(formattedData, d => timeHour.floor(d.date));

    const hoursInLastWeek = [];
  
    const processData = [];
    const maxReqArr = [];

    // Generate hoursInLastWeek array with percentiles set to zero
    const currentHour = new Date();
    currentHour.setHours(currentHour.getHours() + 1);

    function startHour() {
        let day = 1;
        let hours = 1;

        const now = new Date(); // Current date and time
        const currentTimestamp = now.getTime(); // Milliseconds since January 1, 1970
        const currentDayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        const previousMidnightTimestamp = currentDayStart.getTime();

        let durationMilliseconds = 0;
        if (duration === "today") {
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "yesterday") {
            hours = 25;
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "7-days") {
            day = 7;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "30-days") {
            day = 30;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "week-to-date") {
            hours = currentHour.getHours();
            const currentDay = now.getDay(); // Day of the week (0 - Sunday, 1 - Monday, etc.)
            const millisecondsPerDay = 24 * 60 * 60 * 1000;
            const currentWeekMidnightTimestamp = ((currentDay === 0 ? 7 : currentDay) - 0) * millisecondsPerDay - millisecondsPerDay + hours * 60 * 60 * 1000;
            durationMilliseconds = currentWeekMidnightTimestamp;
        } else if (duration === "month-to-date") {
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
            durationMilliseconds = currentTimestamp - startOfMonth.getTime() + day * hours * 60 * 60 * 1000;;

        }
        return new Date(currentHour.getTime() - durationMilliseconds);

    }
  
    function endHour() {
        if (duration === "yesterday") {
            const now = new Date(); // Current date and time
            return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        } else {
            return currentHour;
        }

    }
  
    const startDuration = startHour().getTime();
    const endDuration = endHour().getTime();

    for (let i = startDuration; i <= endDuration; i += 60 * 60 * 1000) {
        const hour =timeHour.floor(new Date(i));
        hoursInLastWeek.push(hour);
    }
  
    let totalSource = 0;
    let totalRequest = 0;
    let totalError = 0;
    let totalSuccess = 0;
    let totalEditor = 0;
    let totalApi = 0;
    let totalShopify = 0;

    for (const hour of hoursInLastWeek) {
        const matchingData = dataByHour.find(([h]) => h.getTime() === hour.getTime());

        if (matchingData) {
            const successes = matchingData[1].map(d => d.success === 1);
            const errors = matchingData[1].map(d => d.error === 1);
            const from_shopify = matchingData[1].map(d => d.shopify === 1);
            const from_api = matchingData[1].map(d => d.api === 1);
            const from_editor = matchingData[1].map(d => d.editor === 1);


            const success = count(successes.filter(d => d === true));
            const error = count(errors.filter(d => d === true));
            const checkout = count(from_shopify.filter(d => d === true));
            const editor = count(from_editor.filter(d => d === true));
            const api = count(from_api.filter(d => d === true));
            const maxReq = Math.max(error, success);
            const allData = error + success;

            maxReqArr.push({ hour, percentile: Math.max(error, success) });

            processData.push({
                hour,
                success,
                error,
                checkout,
                api,
                editor,
                maxReq,
                allData
            });

            if(source !== "all"){
                const from_source = source === "checkout" ?matchingData[1].map(d => d.shopify === 1):matchingData[1].map(d => d[source] === 1);
                const source_success = count(from_source.filter(d => d === true));
                totalSource += source_success;
            }

            totalRequest += error + success;
            totalError += error;
            totalSuccess += success;
            totalEditor += editor;
            totalApi += api;
            totalShopify += checkout;

        } else {
            maxReqArr.push({ hour, percentile: 0 });

            processData.push({
                hour,
                success:0,
                error:0,
                checkout:0,
                api:0,
                editor:0,
                maxReq:0,
                allData:0
            });
        }
    }

    if(source === "checkout"){
        totalRequest = totalShopify;
    }else if(source === "api"){
        totalRequest = totalApi;
    }else if(source === "editor"){
        totalRequest = totalEditor;
    }

    if (max(maxReqArr, d => d.percentile) !== 0 && source === "all") {
        totalSuccess = Math.round(totalSuccess / totalRequest * 100);
        totalError = 100 - totalSuccess;

        totalSuccess = totalSuccess + "%";
        totalError = totalError + "%";
    }else if(max(maxReqArr, d => d.percentile) !== 0 && source !== "all"){
        totalSuccess = Math.round(totalSource / totalRequest * 100);
        totalError = 100 - totalSuccess;

        totalSuccess = totalSuccess + "%";
        totalError = totalError + "%";

    } else {
        totalSuccess = "0%";
        totalError = "0%";
    }
  
    return {processData,totalRequest,totalError,totalSuccess,totalEditor,totalApi,totalShopify};
}

export const processedShippingRateData = (filteredPriceData,duration,currency) => {

    let formattedData = filteredPriceData;
    if(currency !== ""){
        formattedData = filteredPriceData.filter(value => value.currency === currency);
    }
    // Preprocess the data to calculate percentiles for each hour
    const dataByHour = groups(formattedData, d => timeHour.floor(d.date));
  
    const hoursInLastWeek = [];
  
    const processData = [];
  
    // Generate hoursInLastWeek array with percentiles set to zero
    const currentHour = new Date();
      currentHour.setHours(currentHour.getHours() + 1);
  
      function startHour() {
          let day = 1;
          let hours = 1;
  
          const now = new Date(); // Current date and time
          const currentTimestamp = now.getTime(); // Milliseconds since January 1, 1970
          const currentDayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
          const previousMidnightTimestamp = currentDayStart.getTime();
  
          let durationMilliseconds = 0;
          if (duration === "today") {
              durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
          } else if (duration === "yesterday") {
              hours = 25;
              durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
          } else if (duration === "7-days") {
              day = 7;
              hours = 24;
              durationMilliseconds = day * hours * 60 * 60 * 1000;
          } else if (duration === "30-days") {
              day = 30;
              hours = 24;
              durationMilliseconds = day * hours * 60 * 60 * 1000;
          } else if (duration === "week-to-date") {
              hours = currentHour.getHours();
              const currentDay = now.getDay(); // Day of the week (0 - Sunday, 1 - Monday, etc.)
              const millisecondsPerDay = 24 * 60 * 60 * 1000;
              const currentWeekMidnightTimestamp = ((currentDay === 0 ? 7 : currentDay) - 0) * millisecondsPerDay - millisecondsPerDay + hours * 60 * 60 * 1000;
              durationMilliseconds = currentWeekMidnightTimestamp;
          } else if (duration === "month-to-date") {
              const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
              durationMilliseconds = currentTimestamp - startOfMonth.getTime() + day * hours * 60 * 60 * 1000;;
  
          }
          return new Date(currentHour.getTime() - durationMilliseconds);
  
      }
  
      function endHour() {
          if (duration === "yesterday") {
              const now = new Date(); // Current date and time
              return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
          } else {
              return currentHour;
          }
  
      }
  
      const startDuration = startHour().getTime();
      const endDuration = endHour().getTime();
  
  
  
      for (let i = startDuration; i <= endDuration; i += 60 * 60 * 1000) {
          const hour =timeHour.floor(new Date(i));
          hoursInLastWeek.push(hour);
      }
  
      let all_durations = [];
  
      for (const hour of hoursInLastWeek) {
          const matchingData = dataByHour.find(([h]) => h.getTime() === hour.getTime());
  
          if (matchingData) {
              const durations = matchingData[1].map(d => d.price);
              const percentile50 = median(durations);
              const percentile75 = quantile(durations, 0.75);
              const percentile99 = quantile(durations, 0.99);
  
              processData.push({
                hour,
                percentile50,
                percentile75,
                percentile99
              })
  
              all_durations.push(...durations);
          } else {
            processData.push({
              hour,
              percentile50:0,
              percentile75:0,
              percentile99:0
            })
          }
      }
  
      let total_percentile50 = "";
      let total_percentile75 = "";
      let total_percentile99 = "";
      let average_rates = "";
  
      if (all_durations.length > 0) {
          total_percentile50 = median(all_durations).toFixed(2)+ currency;
          total_percentile75 = quantile(all_durations, 0.75).toFixed(2)+ currency;
          total_percentile99 = quantile(all_durations, 0.99).toFixed(2)+ currency;
          average_rates = mean(all_durations).toFixed(2);
      }
      
      return {processData,total_percentile50,total_percentile75,total_percentile99,average_rates};
}

export const processedRequestDistributionData = (formattedData,duration) => {
    // Preprocess the data to calculate percentiles for each hour
    const dataByHour = groups(formattedData, d => timeHour.floor(d.date));
  
    const hoursInLastWeek = [];

  
    // Generate hoursInLastWeek array with percentiles set to zero
    const currentHour = new Date();
    currentHour.setHours(currentHour.getHours() + 1);
  
    function startHour() {
        let day = 1;
        let hours = 1;

        const now = new Date(); // Current date and time
        const currentTimestamp = now.getTime(); // Milliseconds since January 1, 1970
        const currentDayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        const previousMidnightTimestamp = currentDayStart.getTime();

        let durationMilliseconds = 0;
        if (duration === "today") {
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "yesterday") {
            hours = 25;
            durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
        } else if (duration === "7-days") {
            day = 7;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "30-days") {
            day = 30;
            hours = 24;
            durationMilliseconds = day * hours * 60 * 60 * 1000;
        } else if (duration === "week-to-date") {
            hours = currentHour.getHours();
            const currentDay = now.getDay(); // Day of the week (0 - Sunday, 1 - Monday, etc.)
            const millisecondsPerDay = 24 * 60 * 60 * 1000;
            const currentWeekMidnightTimestamp = ((currentDay === 0 ? 7 : currentDay) - 0) * millisecondsPerDay - millisecondsPerDay + hours * 60 * 60 * 1000;
            durationMilliseconds = currentWeekMidnightTimestamp;
        } else if (duration === "month-to-date") {
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
            durationMilliseconds = currentTimestamp - startOfMonth.getTime() + day * hours * 60 * 60 * 1000;;

        }
        return new Date(currentHour.getTime() - durationMilliseconds);

    }

    function endHour() {
        if (duration === "yesterday") {
            const now = new Date(); // Current date and time
            return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
        } else {
            return currentHour;
        }

    }

    const startDuration = startHour().getTime();
    const endDuration = endHour().getTime();



    for (let i = startDuration; i <= endDuration; i += 60 * 60 * 1000) {
        const hour =timeHour.floor(new Date(i));
        hoursInLastWeek.push(hour);
    }

    let all_durations = [];

    for (const hour of hoursInLastWeek) {
        const matchingData = dataByHour.find(([h]) => h.getTime() === hour.getTime());

        if (matchingData) {
            const durations = matchingData[1].map(d => d.duration);
            
            all_durations.push(...durations);
        } 
    }

    let processData = [];
    let dataTemplate = {};

    let maxDuration = 10;

    if (all_durations.length > 0) {
        maxDuration = Math.round(Math.max(...all_durations) * 2) / 2;
        maxDuration = maxDuration > 10 ? maxDuration : 10;
    }

    for (let i = 0; i <= maxDuration; i += 0.5) {
        dataTemplate[i.toFixed(1)] = 0;
    }

    let lessthan10 = 0;
    let greaterthan10 = 0;
    let data_median = 0;

    if (all_durations.length > 0) {
        // Convert durations to numbers
        var durationsNum = all_durations.map(function (d) {
            return Math.round(d * 2) / 2;
        });

        // Calculate the frequency of durations
        var frequencies = {};
        var totalRequest = 0;
        for (let i = 0; i < durationsNum.length; i++) {
            let duration_1 = durationsNum[i].toFixed(1); // Round to one decimal place
            frequencies[duration_1] = frequencies[duration_1] ? frequencies[duration_1] + 1 : 1;

        }

        Object.keys(frequencies).forEach(duration => {
            totalRequest += parseInt(frequencies[duration]);
        })

        // Prepare data for the pie chart
        processData = Object.keys(dataTemplate).map(function (key) {
            if (key in frequencies) {
                return { duration: parseFloat(key), Percentile: Math.round(frequencies[key] * 100 / totalRequest) };
            } else {
                return { duration: parseFloat(key), Percentile: 0 };
            }

        });


        all_durations.map(duration => duration < 10 ? lessthan10++ : greaterthan10++);

        let requestSize = all_durations.length;
        lessthan10 = Math.round(lessthan10 * 100 / requestSize);
        greaterthan10 = 100 - lessthan10 + "%";
        lessthan10 = lessthan10 + "%";

       data_median = median(all_durations).toFixed(2);

    } 

    if (!processData.length) {
        processData = Array.from({ length: 21 }, (v, i) => ({ duration: i, Percentile: 0 }));
    }
    
    return {processData,greaterthan10,lessthan10,data_median};
}

export function exportImage(svgRef,type) {

    const svg = svgRef.current.querySelector("svg");
    const createStyleElementFromCSS = () => {
        const sheet = { cssRules: ['.Polaris-Text--bodySm{font-weight:450;line-height:1rem;font-size:0.75rem} .Polaris-Text--headingSm {font-size:0.8125rem;line-height: 1.25rem;font-weight:650} svg { background: white;font-family: Helvetica, Arial, sans-serif; font-size:0.75rem}'] }

        const styleRules = [];
        for (let i = 0; i < sheet.cssRules.length; i++) {
            styleRules.push(sheet.cssRules[i]);
        }

        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(styleRules.join(' ')))

        return style;
    };

    let svg_width = svg.getAttribute("width");
    let svg_height = svg.getAttribute("height");

    svg.setAttribute("width", svg_width * 2);
    svg.setAttribute("height", svg_height * 2);

    // CSS must be explicitly embedded
    const style = createStyleElementFromCSS();
    svg.insertBefore(style, svg.firstChild);

    const data = (new XMLSerializer()).serializeToString(svg);
    const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8'
    });


    style.remove();
    svg.setAttribute("width", svg_width);
    svg.setAttribute("height", svg_height);

    const url = URL.createObjectURL(svgBlob);
    const img = new Image();
    img.src = url;
    img.addEventListener('load', () => {
        // draw the image on an ad-hoc canvas
        const bbox = svg.getBBox();
        const scale = 2;
        const canvas = document.createElement('canvas');
        canvas.width = bbox.width * scale;
        canvas.height = bbox.height * scale;

        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, bbox.width * scale, bbox.height * scale);

        if (type === "jpeg" || type === "png") {
            canvas.toBlob((blob) => {
                if (type === 'jpeg') {

                    openFile("chart.jpg", blob);

                } else if (type === 'png') {

                    openFile("chart.png", blob);
                }

            })

        }
        else if (type === "svg") {

            openFile("chart.svg", svgBlob);

        }

    });

}

function openFile(filename,blob) {
const a = document.createElement('a');
a.href = URL.createObjectURL(blob);
a.download = filename;
document.body.appendChild(a);
a.click();
document.body.removeChild(a);
}

function getStartHour(duration, currentHour) {
let day = 1;
let hours = 1;

const now = new Date(); // Current date and time
const currentTimestamp = now.getTime(); // Milliseconds since January 1, 1970
const currentDayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
const previousMidnightTimestamp = currentDayStart.getTime();

let durationMilliseconds = 0;
if (duration === "today") {
    durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
} else if (duration === "yesterday") {
    hours = 25;
    durationMilliseconds = currentTimestamp - previousMidnightTimestamp + day * hours * 60 * 60 * 1000;
} else if (duration === "7-days") {
    day = 7;
    hours = 24;
    durationMilliseconds = day * hours * 60 * 60 * 1000;
} else if (duration === "30-days") {
    day = 30;
    hours = 24;
    durationMilliseconds = day * hours * 60 * 60 * 1000;
} else if (duration === "week-to-date") {
    hours = currentHour.getHours();
    const currentDay = now.getDay(); // Day of the week (0 - Sunday, 1 - Monday, etc.)
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const currentWeekMidnightTimestamp = ((currentDay === 0 ? 7 : currentDay) - 0) * millisecondsPerDay - millisecondsPerDay + hours * 60 * 60 * 1000;
    durationMilliseconds = currentWeekMidnightTimestamp;
} else if (duration === "month-to-date") {
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
    durationMilliseconds = currentTimestamp - startOfMonth.getTime() + day * hours * 60 * 60 * 1000;;

}
return new Date(currentHour.getTime() - durationMilliseconds);

}

function getEndHour(duration, currentHour) {

if (duration === "yesterday") {
    const now = new Date(); // Current date and time
    return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set the current day to midnight
} else {
    return currentHour;
}

}

export function savetoCSV(export_format, duration, data_name) {

    const currentHour = new Date();
    currentHour.setHours(currentHour.getHours() + 1);

    const startHour = getStartHour(duration, currentHour);
    const endHour = getEndHour(duration, currentHour);


    // Filter the data based on the timestamp range
    const filteredData = export_format.filter((d) => {
        const timestamp = new Date(d.date);
        return timestamp >= startHour && timestamp <= endHour;
    });

    if (filteredData.length === 0) {
        console.log("No data is available for this time range");
        return
    }

    let transformedData = filteredData.map((d) => {
        const { date, ...rest } = d;
        return { timestamp: date, ...rest };
    });

    const hasShopifyKey = transformedData.some((d) => "shopify" in d);
    if (hasShopifyKey) {
        transformedData = transformedData.map((d) => {
            const { shopify, ...rest } = d;
            return { ...rest, checkout: shopify };
        });
    }

    transformedData.forEach((d) => {
        const dateObj = new Date(d.timestamp);
        d.date = dateObj.toLocaleString(); // Modify the format as needed
    });

    // Determine the keys dynamically
    const keys = Object.keys(transformedData[0]);

    // Reorder the keys to bring "timestamp" as the second key
    const reorderedKeys = [...keys.filter(key => key !== 'date').slice(0, 1), 'date', ...keys.filter(key => key !== 'date').slice(1)];

    // Reorder the keys in each object
    const reorderedData = transformedData.map((d) => {
        const reorderedObj = {};
        reorderedKeys.forEach((key) => {
            reorderedObj[key] = d[key];
        });
        return reorderedObj;
    });

    const csvdata = csvFormat(reorderedData);
    const blob = new Blob([csvdata], { type: 'text/csv' });

    let filename = data_name + ".csv";
    openFile(filename, blob)


}
  