import {Page, Layout, LegacyCard, Text,Link, InlineStack, BlockStack,Icon, Button,Badge,FooterHelp,Banner} from '@shopify/polaris';
import {CheckCircleIcon, XCircleIcon } from '@shopify/polaris-icons'; 
import { useState } from 'react';

function Plans() {
    const demoMessage ="You are now using JsRates in the demo plan to edit, test, and validate your shipping rates codes. Upgrade to the full plan for unlimited requests, full checkout integration, access to your own JsRates API, increased AI characters monthly quota, and access to JsRates VS Code extension.";
    const fullMessage = "The full development plan grants you unlimited requests, full checkout integration, access to your own JsRates API, and access to JsRates VS Code extension.";

    const [plan,setPlan] = useState("full");
    const [planMessage,setPlanMessage] = useState(fullMessage)

    const changePlan = ()=>{
        setPlan(plan==="full"?"demo":"full");
        setPlanMessage(plan==="full"?demoMessage:fullMessage);
    }

    const demoFeatures = ["Unlimited time", "Up to 1,000 requests", "Up to 500,000 AI characters", "Checkout test mode activation", "AI-assisted coding", "Custom OpenAI GPT", "VS Code integration", "Dedicated API endpoint", "Debugging tools", "Script validation", "Test input data", "Third-party API connectivity", "Modular functionality", "Data and code importation", "Performance tracking", "Real-time shipping rates", "Courier API links"];
    const fullFeatures = ["Unlimited time", "Unlimited requests", "Monthly 5,000,000 AI characters", "Full checkout activation", "AI-assisted coding", "Custom OpenAI GPT", "VS Code integration", "Dedicated API endpoint", "Debugging tools", "Script validation", "Test input data", "Third-party API connectivity", "Modular functionality", "Data and code importation", "Performance tracking", "Real-time shipping rates", "Courier API links"];

    const listDemoFeatures = demoFeatures.map((feature,index) =>( 
        <InlineStack id={index} gap={100}>
            <div>{index !== 6 && index !== 7?<Icon source={CheckCircleIcon} tone="subdued" />:<Icon source={XCircleIcon} tone="critical" />}</div>
            <Text as="p" variant="bodySm" >{feature}</Text>
        </InlineStack>
    ));

    const listFullFeatures = fullFeatures.map((feature,index) =>( 
        <InlineStack id={index} gap={100}>
            <div><Icon source={CheckCircleIcon} tone="subdued" /></div>
            <Text as="p" variant="bodySm">{feature}</Text>
        </InlineStack>
    ));

    const headerTitle = plan==="full"?"Current Plan: Full":"Curren Plan: Demo";

    const demoBadge = plan==="demo"?(
        <div style={{position:"relative"}}>
            <div style={{position:"absolute",top:"-8px",zIndex:"var(--p-z-index-2)",left:"var(--p-space-400)"}}><Badge tone="info">Your plan</Badge></div>
        </div>):null;

    const fullBadge = plan==="full"?(
        <div style={{position:"relative"}}>
            <div style={{position:"absolute",top:"-8px",zIndex:"var(--p-z-index-2)",left:"var(--p-space-400)"}}><Badge tone="info">Your plan</Badge></div>
        </div>):null;

        return (
            <Page title="Pick your plans">
            <Layout>
                <Layout.Section>
                    <div style={{paddingBottom:"var(--p-space-400)"}}>
                    <Banner title={headerTitle}>
                        <BlockStack gap="200">
                            <Text as="p" tone="subdued">{planMessage}</Text>
                            <Link url="https://help.jsrates.com/article/subscription-plans" external>Read more about Plansr</Link>
                        </BlockStack>
                    </Banner>
                    </div>
                </Layout.Section>

                <Layout.Section  variant="oneHalf">
                    {demoBadge}
                    <LegacyCard>
                        <div style={{height:"var(--p-space-1600)"}}>
                            <LegacyCard.Section>                   
                                <InlineStack align="space-between">
                                    <Text as="p" variant="headingXl">Demo</Text>
                                    <Text as="p" variant="headingXl">Free</Text>
                                </InlineStack>                   
                            </LegacyCard.Section>
                        </div>
                        <LegacyCard.Section subdued title="features">
                            <BlockStack gap="400">
                                {listDemoFeatures}
                            </BlockStack>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <Button fullWidth disabled={plan==="demo"} size="large" variant="primary" onClick={changePlan}>Choose demo</Button>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Layout.Section>

                
                <Layout.Section  variant="oneHalf">
                    {fullBadge}
                    <LegacyCard>
                        <div style={{height:"var(--p-space-1600)"}}>
                            <LegacyCard.Section>                   
                                <InlineStack align="space-between">
                                    <Text as="p" variant="headingXl">Full</Text>
                                    <BlockStack>
                                        <Text as="p" variant="headingXl">$35</Text>
                                        <Text as="p" tone="subdued" variant="bodyXs">per 30 days</Text>
                                    </BlockStack>                            
                                </InlineStack>                   
                            </LegacyCard.Section>
                        </div>
                        <LegacyCard.Section subdued title="features">
                            <BlockStack gap="400">
                                {listFullFeatures}
                            </BlockStack>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <Button fullWidth disabled={plan==="full"} size="large" variant="primary" onClick={changePlan}>You are full</Button>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Layout.Section>

                <Layout.Section>
                    <LegacyCard title="FAQ">
                        <LegacyCard.Section title="Cancelling">
                            <Text as="p" tone="subdued" variant="bodyMd">You can cancel the paid plan any time by switching to the demo plan.</Text>
                            <Text as="p" tone="subdued" variant="bodyMd">Your full plan subscription will stay active until the paid 30-day cycle ends because Shopify operates on a 30-day billing cycle.</Text>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Layout.Section>            
            </Layout>

            <FooterHelp>
                See more about{' '}
                <Link url="https://help.jsrates.com/article/subscription-plans" external>
                    Plans
                </Link>
                </FooterHelp>
            </Page>
    );
}

export default Plans;