import { IndexTable,EmptySearchResult,Spinner} from '@shopify/polaris';
import { useEffect, useContext, useState, useCallback} from 'react';
import { DataContext } from "../DataContext";


const lintCode = (code) => {
    // You can specify options and globals as needed:
    const options = {
        undef: true,
        unused: true,
        asi: true,
        esversion: 11,
        module: true,
        browser: true,
        futurehostile: true,
        strict: "implied",
        globals: {
        },
        latedef: true,
        maxerr: 1000,
    };

    const globals = {
      console: true,
      module: true
    };
  
    // Run JSHINT on your code
    window.JSHINT(code, options, globals);
  
    // Get the results from JSHINT
    const results = window.JSHINT.data();
    if (results.errors) {
        const jshitn_errors = results.errors;
        jshitn_errors.sort((a, b) => (a.line > b.line) ? 1 : ((b.line > a.line) ? -1 : 0)); //sort errors with line number
        return jshitn_errors.map((error) => {
            return {
                line: error.line,
                character: error.character,
                reason: error.reason
            };
        });
    }
    return [];
  };
  

const ProblemsTable = ({active_file}) => {
    const {currentCode,currentFileName,problemsData, setProblemsData, probemsDataLoaded, setProblemsDataLoaded,currentCodeEditor} = useContext(DataContext);
    const [loadStatus,setLoadStatus] = useState(false);
    
    useEffect(() => {
        const generateProblems = async () => {
            setLoadStatus(false);
            const problems = lintCode(currentCode);
            setProblemsData(problems);
            setLoadStatus(true);
        };
        if(!probemsDataLoaded){
            generateProblems();
            setProblemsDataLoaded(true);
        }else{
            setLoadStatus(true); 
        }
        

    }, [setLoadStatus,setProblemsData,probemsDataLoaded,setProblemsDataLoaded,currentCode]);

    const handleClick = useCallback((error)=>{
        
        if(currentCodeEditor && active_file === currentFileName){
            currentCodeEditor.resize(true);
            currentCodeEditor.scrollToLine(error.line, true, true, function () { });
            currentCodeEditor.gotoLine(error.line, 0, true);
        }
        
    },[currentCodeEditor,active_file,currentFileName]);

    const rowMarkup = problemsData.map((problem,ind) =>{
        return (
            <IndexTable.Row id={ind} key={ind} position={ind} onClick={()=>handleClick(problem)}>
                <IndexTable.Cell>{problem.line}</IndexTable.Cell>
                <IndexTable.Cell>{problem.reason}</IndexTable.Cell>                
            </IndexTable.Row>
        )   
    });

    const resourceName = {
        singular: 'problem',
        plural: 'problems',
      };

    const emptyStateMarkup = (
        <EmptySearchResult
            title={`0 problem in ${active_file}.js`}
            description={`No problems have been detected in ${active_file}.js`}
            withIllustration
        />
    );

    

    return (
        <>
        {loadStatus?
            <IndexTable 
                           
                resourceName={resourceName}
                itemCount={problemsData.length}
                selectable={false}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Line'},
                    {title: 'Problem'}
                ]}
            >
                {rowMarkup}

            </IndexTable>
            :
            <div style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <Spinner /> </div>
        }
        </>
       
    );
};

export default ProblemsTable;
