import { useState, useCallback } from 'react';
import {Page, LegacyCard, Button, Layout, Banner, BlockStack, Link, Text,Checkbox,ChoiceList, Card,Box} from '@shopify/polaris';


function Settings() {
  const plan = "demo";

  const [checkedTestMode, setCheckedTestMode] = useState(false);
  const [checkedLogs, setcheckedLogs] = useState(["editor_logs","shopify_logs","api_logs"]);

  const handleTestModeChange = useCallback(
    (newChecked) => setCheckedTestMode(newChecked),
    [],
  );

  const handleLogsChange = useCallback(
    (newChecked) => setcheckedLogs(newChecked),
    [],
  );

  const demoCheckOutStatus = "Enable JsRates at checkout";
  const fullCheckoutStatus = "Disable JsRates at checkout";

  const demoCheckOutStatusDesc = "JsRates is disabled at checkout by default. Enable JsRates to receive shipping rates at checkout.";
  const fullCheckoutStatusDesc = "By disabling JsRates, you will not recieve shipping rates from JsRates at checkout.";

  const checkoutStatus = plan === "full"?fullCheckoutStatus:demoCheckOutStatus;
  const checkoutStatusDesc = plan === "full"?fullCheckoutStatusDesc:demoCheckOutStatusDesc;

  const demoCheckoutStatusContent = (
    <BlockStack gap="400">
      <Banner>
        <Text as="p" tone="subdued">
        You will need the third-party carrier-calculated shipping activated to enable JsRates on the checkout page. For more information, consult <Link url="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers" external>Shopify support</Link>
        </Text>
      </Banner>

      <Banner
        title="JsRates is disabled at checkout."
        action={{content: 'Enable jsrates',disabled:true}}
        tone="warning"
      >
        <Text as="p" tone="subdued">Enable JsRates at checkout to start receiving JsRates shipping rates at your checkout page. Please note that you need to be in full subscription plan to enable JsRates at checkout. Please make sure to test and validate your code before enabling JsRates at checkout. For testing, you can enable JsRates checkout in test mode. If you are in demo subscription plan, you have access to JsRates checkout in test mode.</Text>
      </Banner>

      <Card>
        <Box paddingBlockEnd="200">
            <Text as="h2" variant="headingSm">
              JsRates checkout test mode
            </Text>
        </Box>
        <Box paddingBlockStart="200" borderStyle="solid" borderWidth="025" borderColor="border" padding="200" borderRadius="200">
          <BlockStack gap="400">
            <Text as="p" tone="subdued">With JsRates checkout test mode, you can test your shipping rates at checkout by entering "JsRates" (case sensitive) as the customer's first and last names during order checkout.</Text>
      
            <Checkbox
              label="Enable JsRates checkout test mode"
              checked={checkedTestMode}
              onChange={handleTestModeChange}
            />
          </BlockStack>        
        </Box>
        <Box paddingBlockStart="400">
          <Button variant="primary" disabled={!checkedTestMode}>Save</Button>
        </Box>      
      </Card>
    </BlockStack>
  );

  const fullCheckoutStatusContent = (
    <BlockStack gap="400">
      <Banner
        title="JsRates is enabled at checkout"
        action={{content: 'Disable jsrates',disabled:false}}
        tone="warning"
      >
        <Text as="p" tone="subdued">By disabling JsRates, you will not receive shipping rate from JsRates at checkout. You can still continue to receive rates from the code editor or JsRates API for testing and validation.</Text>
      </Banner>
    </BlockStack>
  );

  const CheckoutStatusContent = plan === "full" ? fullCheckoutStatusContent:demoCheckoutStatusContent;

  const LogsContent = (
    <BlockStack  gap="400">

      <Card>
          <Box paddingBlockEnd="200">
            <Text as="h2" variant="headingSm">
                Configure logs
              </Text>
          </Box>
          
          <Box paddingBlockStart="200" borderStyle="solid" borderWidth="025" borderColor="border" padding="200" borderRadius="200">
            <BlockStack gap="400">
              <Banner><p>Please be aware that if you choose to disable the log feature, it may become more challenging to diagnose any issues with your code.</p></Banner>
            
              <ChoiceList
                allowMultiple
                choices={[
                  {
                    label: 'Disable code editor rates logs',
                    value: 'editor_logs',
                  },
                  {
                    label: 'Disable API rates logs',
                    value: 'api_logs',
                  },
                  {
                    label: 'Disable checkout rates logs',
                    value: 'shopify_logs',
                  }
                ]}
                selected={checkedLogs}
                onChange={handleLogsChange}
              />

              
            </BlockStack>
          </Box>
          <Box paddingBlockStart="400">
            <Button variant="primary" disabled={checkedLogs.length === 0}>Save</Button>
          </Box>      
          
    
      </Card>

      <LegacyCard title="Clear stored logs">
        <LegacyCard.Section>
          <Text as="p" tone="subdued">This will permanently delete all the existing log data.</Text>
        </LegacyCard.Section>
        <LegacyCard.Section>
        <Button variant="secondary">Clear logs</Button>
        </LegacyCard.Section>

      </LegacyCard>

    </BlockStack>
  );

  return (
    <Page title="Settings">
      <Layout>
        <Layout.AnnotatedSection id="js_enable_layout" title={checkoutStatus} description={checkoutStatusDesc}>
            {CheckoutStatusContent}        
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          id="js_logs_layout"
          title="Logs" 
          description="JsRates stores up to 50 encrypted logs of the most recent shipping rates data that was sent from your checkout page or the code editor. These logs are used to help you debug any errors that might occur during the shipping rates calculation process."
        >
          {LogsContent}       
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}

export default Settings;

