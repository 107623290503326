import { Modal} from '@shopify/polaris';
import { useEffect, useContext, useState, useRef} from 'react';
import { DataContext } from "../DataContext";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import ace from 'ace-builds/src-noconflict/ace';
ace.config.set('basePath', process.env.PUBLIC_URL + '/ace-builds');
ace.config.set("modePath", process.env.PUBLIC_URL + '/ace-builds');
ace.config.set("workerPath", process.env.PUBLIC_URL + '/ace-builds'); 
ace.config.set("themePath", process.env.PUBLIC_URL + '/ace-builds'); 

const LogsModal = () => {
    const {logsModalActive,handleLogsModalChange,logsJSON} = useContext(DataContext);
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const logRef = useRef(null);

    useEffect(()=>{
        if(Object.keys(logsJSON).length > 0){
            setTitle(`Logs ${logsJSON.local_date}`);
            setData(logsJSON);
        }
        if(logRef.current){
            logRef.current.editor.scrollToLine(1, true, true, function () { });
            logRef.current.editor.gotoLine(1, 1, true);
        }
        
    },
    [logsJSON]
    )

    return (
        <Modal
            size='large'
            open={logsModalActive}
            onClose={handleLogsModalChange}
            title={title}
        >
            <Modal.Section flush>
                <AceEditor
                    ref={logRef}
                    mode="json"
                    theme="chrome"
                    name="log"
                    value={JSON.stringify(data, null, 4)}
                    editorProps={{ $blockScrolling: true }}
                    style={{ width: '100%', height: 'calc(100vh - 10rem)', font:"0.8125rem / normal 'monolisa','Space Mono','monospace'" }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableSnippets: true,
                        highlightActiveLine:true,
                        showPrintMargin:false,
                        fixedWidthGutter:true,
                        enableLiveAutocompletion: true,
                        useWorker: true,
                        readOnly: true                    
                    }}
                />
            </Modal.Section>
        </Modal>
    );
};

export default LogsModal;
