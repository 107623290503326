import {useContext, useEffect, useRef, useCallback} from "react";
import { DataContext } from "../DataContext";
import { Button, ButtonGroup, Card, InlineStack, Box, Text} from "@shopify/polaris";
import {XIcon,MaximizeIcon,MinimizeIcon} from '@shopify/polaris-icons';

const CodeOutput = ({children,title}) => {
    const { expandStatus, setExpandStatus,codeOutputStatus,setCodeOutputStatus,setCodeEditorHeight,codeOutputContentHeight,setCodeOutputContentHeight,codeOutputHeight,setCodeOutputHeight} = useContext(DataContext);
    const resizerRef = useRef(null);
    const outputRef = useRef(null);

    useEffect(()=>{
        setCodeEditorHeight(`calc(100vh - 11rem - ${codeOutputHeight})`);

        const treshold = window.innerHeight - 226; //not to drag it below the lower window

        let startY = 0;
        resizerRef.current.addEventListener("mousedown", (e) => {
            startY = e.clientY - resizerRef.current.offsetTop + 120;
            console.log("startY",startY)
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('selectstart', disableSelect);
        });
    
        function disableSelect(e) {
            e.preventDefault();
        }
        // Define the onMouseMove function
        function onMouseMove(e) {
    
            let offset = (e.clientY - startY) + 'px';
            let offset_num = e.clientY - startY;  
            if (offset_num >= 0 && offset_num < treshold) {
                if(offset_num === 0){
                    setExpandStatus(true)
                }
                const editorHeight = offset;
                setCodeEditorHeight(editorHeight);
                const outputHeight = 'calc(100vh  -  ' + offset + ' - 11rem)';
                setCodeOutputHeight(outputHeight);
                setCodeOutputContentHeight(`calc(${outputHeight} - 3rem)`);
            }else if(offset_num >= treshold){
                console.log(offset_num)
                setExpandStatus(true);
                offset = treshold +"px";                
                const editorHeight = offset;
                setCodeEditorHeight(editorHeight);
                const outputHeight = 'calc(100vh  -  ' + offset + ' - 11rem)';
                setCodeOutputHeight(outputHeight);
                setCodeOutputContentHeight(`calc(${outputHeight} - 3rem)`);
            }else{
                setExpandStatus(true);
            }
    
        }
    
        // Define the onMouseUp function
        function onMouseUp() {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('selectstart', onMouseUp);
        }
        
    },[codeOutputHeight,setCodeEditorHeight,setCodeOutputContentHeight,setCodeOutputHeight,setExpandStatus]);


    const handleCloseClick = useCallback(()=>
        {
            setCodeOutputStatus(!codeOutputStatus);
            setCodeOutputHeight("400px");
            setCodeOutputContentHeight("calc(400px - 3rem");
            setCodeEditorHeight("calc(100vh - 11rem");
        },
        [setCodeOutputContentHeight,codeOutputStatus,setCodeOutputStatus,setCodeOutputHeight,setCodeEditorHeight]
    )

    const handleExpandClick = useCallback(()=>
        {   
            if(expandStatus){
                setCodeOutputHeight("400px");
                setCodeOutputContentHeight("calc(400px - 3rem");
                setCodeEditorHeight("calc(100vh - 11rem");
            }else{
                setCodeOutputHeight("calc(100vh - 11rem");
                setCodeOutputContentHeight("calc(100vh - 14rem");
                setCodeEditorHeight("0px");
            }
            setExpandStatus(!expandStatus)
            
        },
        [setCodeOutputContentHeight,setCodeOutputHeight,setCodeEditorHeight,expandStatus,setExpandStatus]
    )


    return (
        <>
        <div ref={resizerRef} role="presentation" id="resizer"></div>
        <Card padding="0">      
            <div ref={outputRef} style={{height:codeOutputHeight}}>
                <Box padding={200} background="nav-bg">
                    <InlineStack align="space-between">
                        <Text as="h3" variant="headingMd">{title}</Text>
                        <ButtonGroup gap="400">
                            <Button icon={expandStatus?MinimizeIcon:MaximizeIcon} size="large" accessibilityLabel={expandStatus?"Minimize":"Expand"} onClick={()=>handleExpandClick()}/>
                            <Button icon={XIcon} size="large" accessibilityLabel="Close" onClick={()=>handleCloseClick()}/>
                        </ButtonGroup>
                    </InlineStack>
                </Box>
                <div style={{overflow:"auto",height:codeOutputContentHeight}}>
                    {children}
                </div>
                
            </div>
        </Card>
        </>
    )
}

export default  CodeOutput;