import { IndexTable,Icon,EmptySearchResult,Spinner, Text } from '@shopify/polaris';
import { StatusActiveIcon, AlertDiamondIcon } from '@shopify/polaris-icons';
import { useEffect, useContext, useState, useCallback} from 'react';
import { DataContext } from "../DataContext";

const fetchLogsData = async (shop_name) => {
    const response = await fetch(`https://jsrates-react-test.pages.dev/api/get-logs?shop_name=${shop_name}`);
    const data = await response.json();
    return data;
    
};


const LogsTable = () => {
    const {shopName,logsData, setLogsData, logsDataLoaded, setLogsDataLoaded,setLogsJSON,setLogsModalActive} = useContext(DataContext);
    const [loadStatus,setLoadStatus] = useState(false);
    
    useEffect(() => {
        const fetchData = async (shop_name) => {
            setLoadStatus(false);
            const payload = await fetchLogsData(shop_name);
            setLogsData(payload);
            setLoadStatus(true);
        };
        if(!logsDataLoaded){
            fetchData(shopName);
            setLogsDataLoaded(true);
        }else{
            setLoadStatus(true); 
        }
        

    }, [setLoadStatus,shopName,setLogsData,logsDataLoaded,setLogsDataLoaded]);

    const handleClick = useCallback((log)=>{
        setLogsJSON(log);
        setLogsModalActive(true);
    },[setLogsJSON,setLogsModalActive]);

    const rowMarkup = logsData.map((log,ind) =>{
        if ("date" in log) {
            let date = new Date(log.date);
            let local_date = date.toLocaleString();

            let row_line = ind;
            //logsData[ind].date = local_date;
            log.local_date = local_date;

            let source = "Editor";
            if ("from_shopify" in log) {
                source = "Checkout";
            } else if ("from_api" in log) {
                source = "API";
            }

            let duration = (log.duration).replace(" sec", "");
            duration = (log.duration).replace(" second", "");

            let duration_warning = "";
            if (parseFloat(duration) > 10) {
                duration_warning = 'critical';
            }
            let origin_country = "-";
            let origin_city = "-";
            let origin_post_code = "-";
            let destination_country = "-";
            let destination_city = "-";
            let destination_post_code = "-";

            if ("DATA" in log && "from_shopify" in log) {
                let origin = log.DATA.origin;
                let destination = log.DATA.destination;

                if (typeof origin === "object") {
                    if ("city" in origin) {
                        origin_city = origin.city;
                        origin_country = origin.country;
                        origin_post_code = origin.postal_code;
                    }
                }

                if (typeof destination === "object") {
                    if ("city" in destination) {
                        destination_city = destination.city;
                        destination_country = destination.country;
                        destination_post_code = destination.postal_code;
                    }
                }

            } else if ("DATA" in log) {
                if ("rate" in log.DATA) {
                    let origin = log.DATA.rate.origin;
                    let destination = log.DATA.rate.destination;

                    if (typeof origin === "object") {
                        if ("city" in origin) {
                            origin_city = origin.city;
                            origin_country = origin.country;
                            origin_post_code = origin.postal_code;
                        }
                    }

                    if (typeof destination === "object") {
                        if ("city" in destination) {
                            destination_city = destination.city;
                            destination_country = destination.country;
                            destination_post_code = destination.postal_code;
                        }
                    }
                }
            }

            let status_icon = <Icon source={StatusActiveIcon} tone="success" />;
            if ("error" in log) {
                status_icon = <Icon  source={AlertDiamondIcon} tone="critical" />;
            }

            return (
                <IndexTable.Row id={row_line} key={row_line} position={row_line} onClick={()=>handleClick(log)}>
                     <IndexTable.Cell>{status_icon}</IndexTable.Cell>
                     <IndexTable.Cell>{local_date}</IndexTable.Cell>
                     <IndexTable.Cell><Text tone={duration_warning}>{log.duration}</Text></IndexTable.Cell>
                     <IndexTable.Cell>{source}</IndexTable.Cell>
                     <IndexTable.Cell>{origin_country}</IndexTable.Cell>
                     <IndexTable.Cell>{origin_city}</IndexTable.Cell>
                     <IndexTable.Cell>{origin_post_code}</IndexTable.Cell>
                     <IndexTable.Cell>{destination_country}</IndexTable.Cell>
                     <IndexTable.Cell>{destination_city}</IndexTable.Cell>
                     <IndexTable.Cell>{destination_post_code}</IndexTable.Cell>
                </IndexTable.Row>
            )

        }else{
            return null
        }
    });

    const resourceName = {
        singular: 'log',
        plural: 'logs',
      };

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No logs data'}
            description={'Run your codes to generate logs, or enable logs in the setting page if it is disabled'}
            withIllustration
        />
    );

    

    return (
        <>
        {loadStatus?
            <IndexTable
            
                resourceName={resourceName}
                itemCount={logsData.length}
                selectable={false}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Status'},
                    {title: 'Time'},
                    {title: 'Duration'},                 
                    {title: 'Source'},
                    {title: 'Origin country'},
                    {title: 'Origin city'},
                    {title: 'Origin postal code'},
                    {title: 'Destination country'},
                    {title: 'Destination city'},
                    {title: 'Destination postal code'},
                ]}
            >
                {rowMarkup}

            </IndexTable>
            :
            <div style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <Spinner /> </div>
        }
        </>
       
    );
};

export default LogsTable;
