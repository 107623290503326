import { useContext, useEffect } from 'react';
import { DataContext } from '../DataContext';
import { Layout, ResourceList, ResourceItem, LegacyCard, Text,Icon } from '@shopify/polaris';
import {BookOpenIcon} from '@shopify/polaris-icons'; 

const HelpContent = ({changeTopic}) => {
    const { helpContentData, fetchHelpContentData } = useContext(DataContext);

    // Ensure data is fetched
    useEffect(() => {
        if (!helpContentData) {
        fetchHelpContentData();
        }
    }, [helpContentData, fetchHelpContentData]);

    const openDocModal = (help_title,topic) => {
        changeTopic({title:topic,handle:help_title});
    }

    // Rendering the content once helpContentData is available
    const renderColumnData = (columnData) => {
        return Object.keys(columnData).map((sectionKey) => (
        <LegacyCard key={sectionKey} title={sectionKey}>
                <ResourceList
                items={columnData[sectionKey]}
                renderItem={(item) => {
                    const { title, handle } = item;
                    return (
                    <ResourceItem
                        id={handle}
                        accessibilityLabel={`View details for ${title}`}
                        media={<Icon source={BookOpenIcon} color="base" />}
                        onClick={()=>openDocModal(handle, title)}
                    >
                        {title}
                    </ResourceItem>
                    );
                }}
                />
        </LegacyCard>
        ));
  };

  

  // Check if helpContentData is not null before using it
  const layouts = helpContentData ? Object.keys(helpContentData).map(layout_key => (
    <div style={{marginTop:"var(--p-space-400)"}}>
        <Layout key={layout_key}>
        <Layout.Section>
            <Text as="h3" variant="headingMd">
            {layout_key}
            </Text>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
            {renderColumnData(helpContentData[layout_key]["Column 1"])}
        </Layout.Section>
        <Layout.Section variant="oneHalf">
            {renderColumnData(helpContentData[layout_key]["Column 2"])}
        </Layout.Section>
        </Layout>
    </div>
  )) : <Text>Loading...</Text>; // Provide a loading state

  return <>{layouts}</>; // Return layouts inside a div
};

export default HelpContent;
