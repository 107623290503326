import React, { createContext, useState, useEffect } from 'react';
import {processReports} from "./assets/js/tools";
import { Spinner } from '@shopify/polaris';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [shopName,setShopName] = useState("");
    const [helpContentData, setHelpContentData] = useState(null);
    const [codeData, setCodeData] = useState("");
    const [currentCode,setCurrentCode] = useState("");
    const [fileNames,setFileNames] = useState([]);
    const [currentFileName,setCurrentFileName] = useState("calculateShippingRates");
    const [editorState, setEditorState] = useState({});
    const [currentCodeEditor,setCurrentCodeEditor] = useState(null); //current code editor ace

    const [codeEditorHeight,setCodeEditorHeight] = useState("calc(100vh - 11rem)");
    const [codeOutputHeight,setCodeOutputHeight] = useState("400px");
    const [codeOutputContentHeight,setCodeOutputContentHeight] = useState("calc(400px - 3rem)");
    const [expandStatus, setExpandStatus] = useState(false); //state of the output expand or shrink status
    const [codeOutputStatus,setCodeOutputStatus] = useState(false); //state of the output opened or closed status

    // use this to store the sate of the active content in the output
    const [activeOutput,setActiveOutput]= useState({
      title:"Loading...",
      component:<div style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <Spinner /> </div>
    });

    const [logsData, setLogsData] = useState([]);
    const [logsDataLoaded, setLogsDataLoaded] = useState(false); //state of logs data is fetched or not

    const [logsJSON, setLogsJSON] = useState({}); //the logs JSON data
    const [logsModalActive, setLogsModalActive] = useState(false); //state of logs JSON modal
    const handleLogsModalChange = () => {   //handles the onClose event of logs JSON modal - when closing it
      setLogsModalActive(!logsModalActive);
    };


    const [problemsData, setProblemsData] = useState([]); //contains the problem data
    const [probemsDataLoaded, setProblemsDataLoaded] = useState(false); //contains the state whether problem data is generated or not. set to true to refresh data

    const [reportData,setReportData] = useState({});
    const [reportDataLoaded,setReportDataLoaded] = useState(false); //for home page shop data fetch button status


  // Fetch data here
  const fetchHelpContentData = async () => {
    const response = await fetch('/api/get-help-content');
    const newData = await response.json();
    setHelpContentData(newData);
  };

  const fetchCodeData = async (shop_name) => {
    const response = await fetch(`https://jsrates-react-test.pages.dev/api/get-code?shop_name=${shop_name}`);
    const data = await response.json();
    if(data.success){
        const codes = data.code;
        setCodeData(codes);
        setCurrentCode(codes.calculateShippingRates);
        const files_names = Object.keys(codes);
        setFileNames(files_names);
    }
    
  };

  const fetchReportData = async (shop_name) => {
    setReportDataLoaded(false);
    const response = await fetch(`https://jsrates-react-test.pages.dev/api/get-reports?shop_name=${shop_name}`);
    const data = await response.json();
    if(data.success){
        const reports = data.data;
        const processed_reports = processReports(reports);
        setReportData(processed_reports);
        setReportDataLoaded(true);
    }
    
  };

  // Fetch data on mount
  useEffect(() => {
    if(shopName){
      fetchCodeData(shopName);
      fetchReportData(shopName);
    }
    fetchHelpContentData();
  }, [shopName]);

  return (
    <DataContext.Provider value={{
        codeEditorHeight,
        setCodeEditorHeight,
        codeOutputHeight,
        setCodeOutputHeight,
        codeOutputContentHeight,
        setCodeOutputContentHeight,
        codeOutputStatus,
        setCodeOutputStatus,
        expandStatus, 
        setExpandStatus,
        activeOutput,
        setActiveOutput,
        logsData, 
        setLogsData,
        logsDataLoaded, 
        setLogsDataLoaded,
        logsJSON, 
        setLogsJSON,
        logsModalActive,
        setLogsModalActive,
        handleLogsModalChange,
        problemsData, 
        setProblemsData, 
        probemsDataLoaded, 
        setProblemsDataLoaded,
        reportDataLoaded,
        shopName,
        setShopName,
        helpContentData,
        fetchHelpContentData,
        codeData,
        setCodeData,
        fetchCodeData,
        currentCode,
        setCurrentCode,
        currentFileName,
        setCurrentFileName,
        fileNames,
        editorState,
        setEditorState,
        currentCodeEditor,
        setCurrentCodeEditor,
        reportData
      }}>
      {children}
    </DataContext.Provider>
  );
};
